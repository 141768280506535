import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import Button from "../../../../components/ui/Button";

import InputBox from "../../../../components/ui/InputBox";

const UpdatePasswordModal = ({show, onHide}) => {

    const { t } = useTranslation();

    const [password, setPassword] = useState("");

    const inputChange = (val) => {
        setPassword(val)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            centered
            >
                <Modal.Body>
                    <div className="setup-2fa-container p-3">
                        <h4>{t("accountSettingPage.updateDetailsModal.heading")}</h4>
                        <p>{t("accountSettingPage.updateDetailsModal.desc")}</p>
                        <div className="my-4">
                            <div className="mt-3 w-100">
                                <InputBox placeholder={'Current Password'} inputType={'password'} inputLabel={'Current Password'} inputChange={inputChange} inputValue={password}/>
                            </div>
                        </div>
                        <div className="d-flex gap-3">
                            <Button buttonText={"Cancel"} buttonType={"secondary"} />
                            <Button buttonText={"Save"} buttonType={"primary"} />
                        </div>
                    </div>
                </Modal.Body>
            
        </Modal>
    )
}

export default UpdatePasswordModal