import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";

import SideNav from "../../components/PostLogin/SideNav";
import DomainTileView from '../../components/PostLogin/Domain/DomainTileView';
import DomainListView from '../../components/PostLogin/Domain/DomainListView';
import SideModal from '../../components/ui/SideModal';
import AddDomainModal from '../../components/PostLogin/Domain/AddDomainModal';

const Domain = () => {

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }


    useEffect(() => {

    })


    return (
        <div className="domain-container">
            <SideNav />
            <div className="domain-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("domainPage.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={() => { setShowAddOrgSidePanel(true) }}>{t("domainPage.addDomain")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search Domains' className="search-input" />
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={() => setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={() => setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>


                <div className="page-main-section p-4">
                    <div className="total-domain-scans d-flex mt-3">
                        <div className="total-domains-container rounded p-3 margin-right">
                            <h4 className="m-0 mt-1">100</h4>
                            <p className='mt-2'>{t("domainPage.totalDomains")}</p>
                        </div>
                        <div className="total-domains-container rounded p-3">

                            <div className='domain-icon'>
                                <h4 className="m-0 mt-1">50</h4>
                                <span className='active-icon mt-3 ms-3'></span>
                                <span className='passive-icon mt-3'></span>
                                <p className='total-scans-text'>{t("domainPage.totalScans")}</p>
                            </div>
                            <div className="p-wrapper d-flex justify-content-between gap-3">
                                <p className='mt-2'><span className='count d-inline-block'>100</span>{t("domainPage.activeScans")}</p>
                                <p className='mt-2'><span className='count-passive'>00</span>{t("domainPage.passiveScans")}</p>
                            </div>
                        </div>
                        
                    </div>
                    <h2>{t("domainPage.pageHeading")}</h2>
                    {viewType === 'tile' && <DomainTileView />}
                    {viewType === 'list' && <DomainListView />}
                </div>
            </div>
            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                <AddDomainModal />
            </SideModal>
        </div>
    )
}

export default Domain;