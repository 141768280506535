import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";


import SideNav from "../../components/PostLogin/SideNav";
import UsersTileView from "../../components/PostLogin/Users/UsersTileView";
import UserListView from "../../components/PostLogin/Users/UsersListView";


const Users = () => {

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');


    return (
        <div className="users-container">
            <SideNav />
            <div className="users-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("users.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button>{t("users.inviteUsers")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search User' className="search-input" />
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <div className="users-header-container p-3 my-3">
                        <div className="d-flex gap-3">
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">100</p>
                                    <div className="user-count mx-3 mt-2">
                                        <span className="active d-inline-block mx-1"></span>
                                        <span className="disbled d-inline-block"></span>
                                        <p>{t("users.totalUsers")}</p>
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"><span className="d-inline-block">100</span> {t("users.activeUsers")}</p>
                                    <p className="disabled-user"><span className="d-inline-block">02</span> {t("users.disabledUsers")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="secondary-heading my-4">{t("users.selectaUser")}</h2>
                    {viewType === "tile" && <UsersTileView />}
                    {viewType === "list" && <UserListView />}
                </div>
            </div>
        </div>
    )
}

export default Users;