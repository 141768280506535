import { React } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import criticalIcon from "../../../../assets/images/organization/critical.svg";
import highIcon from "../../../../assets/images/organization/high.svg";
import mediumIcon from "../../../../assets/images/organization/medium.svg";
import LowIcon from "../../../../assets/images/organization/low.svg";
import portfolioIcon from "../../../../assets/images/organization/portfolio.svg";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import Button from "../../../../components/ui/Button";
import { formatNumber } from "../../../../utils/util";


const OrganizationListView = ({organizationList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const buttonClicked = (data) => {
        navigate(`/organization/details/${data.organization_id}`)
    }

    return (
        <Row>
            <Col md="12">
                <div className="list-view-container organization-list-view-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t('organizationPage.listView.organization')}</th>
                                <th>{t('organizationPage.listView.domains')}</th>
                                <th>{t('organizationPage.listView.newFindings')}</th>
                                <th>{t('organizationPage.listView.domainPorfolioRisk')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                organizationList?.results.length > 0 ? 
                                organizationList?.results.map((data,index)=>{
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <ProfileAvatar type="domain" name={data.name} imageUrl={data.logo} />
                                                <div className="organization-status">
                                                    {data.business_impact === "critical" && <p className="crictical">{t("organizationPage.BusinessImpact")} <span><img src={criticalIcon} alt="critical" />{data.business_impact}</span></p>}
                                                    {data.business_impact === "high" && <p className="high">{t("organizationPage.BusinessImpact")} <span><img src={highIcon} alt="high" />{data.business_impact}</span></p>}
                                                    {data.business_impact === "medium" && <p className="medium">{t("organizationPage.BusinessImpact")} <span><img src={mediumIcon} alt="medium" />{data.business_impact}</span></p>}
                                                    {data.business_impact === "low" && <p className="low">{t("organizationPage.BusinessImpact")} <span><img src={LowIcon} alt="low" />{data.business_impact}</span></p>}
                                                    <p className="porfolio">{t("organizationPage.BusinessType")} <span><img src={portfolioIcon} alt="critical" />{data.type}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="domain-list-section">
                                                    <span className="domain-list">google.com</span>
                                                    <span className="domain-border"></span>
                                                    <span className="domain-list">abc.xyz</span>
                                                    <span className="domain-border"></span>
                                                    <span className="domain-list">gmail.com</span>
                                                    <span className="domain-border"></span>
                                                    <span className="domain-list">youtube.com</span>
                                                    <span className="more-domains">+3</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="finding-list">
                                                    <ul type="none" className="m-0 p-0">
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.assetDiscovery")}</span>
                                                            <span>{formatNumber(data.stats.new_findings.asset_discovery)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                            <span>{formatNumber(data.stats.new_findings.external_detected_vulnerabilities)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.DarkWeb")}</span>
                                                            <span>{formatNumber(data.stats.new_findings.darkweb)}</span>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <span>{t("organizationPage.concerningExposures")}</span>
                                                            <span>{formatNumber(data.stats.new_findings.concerning_exposures)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="risk-count">
                                                    <span className="critical">Critical {formatNumber(data.stats.domain_portfolio_risks.critical_risk)}</span>
                                                    <span className="high">High {formatNumber(data.stats.domain_portfolio_risks.high_risk)}</span>
                                                    <span className="medium">Medium {formatNumber(data.stats.domain_portfolio_risks.low_risk)}</span>
                                                    <span className="low">Low {formatNumber(data.stats.domain_portfolio_risks.medium_risk)}</span>
                                                </div> 
                                            </td>
                                            <td>
                                                <div className='organization-list-view d-flex'>
                                                    <Button buttonText={"View Details"} buttonType="secondary" buttonClicked={() => buttonClicked(data)}/>
                                                    <Button buttonText={"View Scan"} buttonType="primary" buttonClicked={buttonClicked}/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                : "No data found"
                            }
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    )
}

export default OrganizationListView;