import { React } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";


import domainIcon from "../../../../assets/images/common/domain.svg";

import Button from "../../../../components/ui/Button";


const DeleteDomainModal = (props) => {

    const { t } = useTranslation();


    return (
        <Modal
            {...props}
            size=""
            centered
            className="delete-organization-modal"
            >
                <Modal.Body>                                
                    <div className="delete-organization-container">
                        <h4>{t("domainDetailsPage.deleteDomain.detailsHeading")}</h4>
                        <div className="organization-related-details p-4 m-2">
                            <p>{t("organizationDetailsPage.deleteOrganization.subText")}</p>
                            <div className="d-flex justify-content-between mt-4 gap-3">
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("organizationDetailsPage.deleteOrganization.totalDomains")}</p>
                                        <p className="domain-count">100</p>
                                    </div>
                                </div>
                                <div className="domain-delete p-3">
                                    <img src={domainIcon} alt="domain" />
                                    <div className="domain-details d-flex justify-content-between align-items-center gap-5">
                                        <p className="domain-text">{t("organizationDetailsPage.deleteOrganization.totalUsers")}</p>
                                        <p className="domain-count">24</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="delete-organization-btn d-flex gap-3 m-2">
                            <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={props.onHide}/>
                            <Button buttonText="Delete" buttonType={"primary"} buttonClicked={props.onHide} />
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    )
}

export default DeleteDomainModal;