import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import dummyProfile from "../../assets/images/side-nav/dummy-profile.png";
import userRole from "../../assets/images/common/user-role.svg"

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import OrganizationTab from "../../components/PostLogin/Users/OrganizationTab";
import ScanHistoryTab from "../../components/PostLogin/Users/ScanHistory";
import UserSessionTab from "../../components/PostLogin/Users/UserSessionTab";


const UserDetails = () => {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("organization");


    return (
        <div className="users-details-container">
            <SideNav />
            <div className="users-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1>Users</h1>
                        <img src={arrowRight} alt="arrow-right"/>
                        <ProfileAvatar type="profile" firstName={"John"} lastName={"Doe"} imageUrl={dummyProfile} />
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            Edit
                        </button>
                        <button>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            Delete
                        </button>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <div className="organization-created d-flex justify-content-between">
                        <h4>User Details</h4>
                    </div>
                    <div className="user-header-container p-3 my-3">
                        <div className="d-flex justify-content-between">
                            <div className="header-details d-flex gap-3">
                                <p>Time Zone: <span>Asia/kolkata</span></p>
                                <p>Joined on: <span>July 21, 2004 5:48</span></p>
                                <p>Two Factor Authentication: <span>Enabled</span></p>
                                <p>Last Scanned on: <span>July 21, 2004 5:48</span></p>
                            </div>
                            <div className="user-role-status d-flex gap-3">
                                <p className="d-flex align-items-center"><span className="d-inline-block mx-1"><img src={userRole} alt="critical" /></span>Role: Administrator</p>
                            </div>
                        </div>
                        <div className="d-flex gap-3 mt-3">
                            <div className="total-domains-container rounded">
                                <p className="total-domain">100</p>
                                <p className="domain-text">Total Scan</p>
                            </div>
                            <div className="total-domains-container rounded">
                                <p className="total-domain">20</p>
                                <p className="domain-text">Total Auto Scan</p>
                            </div><div className="total-domains-container rounded">
                                <p className="total-domain">40</p>
                                <p className="domain-text">Total Organization</p>
                            </div>
                        </div>
                    </div>
                    <div className="organization-main-container p-3">
                        <div className="orgs-tab-container d-flex rounded">
                            <p className={`rounded text-center ${activeTab === "organization" ? "active" : ""}`} onClick={()=>setActiveTab("organization")}>Organization</p>
                            <p className={`rounded text-center ${activeTab === "scanHistory" ? "active" : ""}`} onClick={()=>setActiveTab("scanHistory")}>Scan History</p>
                            <p className={`rounded text-center ${activeTab === "userSession" ? "active" : ""}`} onClick={()=>setActiveTab("userSession")}>User Session</p>
                        </div>
                        {activeTab === "organization" && <OrganizationTab />}
                        {activeTab === "scanHistory" && <ScanHistoryTab />}
                        {activeTab === "userSession" && <UserSessionTab/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails