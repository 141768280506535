import { React, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import InputBox from "../../components/ui/InputBox";
import Button from "../../components/ui/Button";
import CheckBox from "../../components/ui/CheckBox";

import "./index.scss";
import { useTranslation } from "react-i18next";

import LeftSection from "../../components/PreLogin/LoginPage/LeftSection.js";
import RightFooter from "../../components/PreLogin/RightFooter";

import { useSelector, useDispatch } from 'react-redux';
import { loginUser, getLoggedInUserDetails } from '../../store/actions/loginAction.js';


const LoginPage = () => {

    const response = useSelector((state) => state.login?.loginResponse);

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [keepLogin, setKeepLogin] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    

    const inputChange = (val, type) => {
        if(type==="text"){
            setUserName(val)
        } else {
            setPassword(val)
        }
    }

    const buttonClicked = () => {
        const formData = new FormData();
        formData.append('username', userName);
        formData.append('password', password);
        setIsLoading(true)
        dispatch(loginUser(formData))
    }

    const isDisabledButton = () => {
        return userName === '' || password === '';
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setKeepLogin(!keepLogin)
        }
    }

    const forgetPasswordClicked = () => {
        navigate("/auth/forget-password");
    }

    const reset2Fa = () => {
        navigate("/auth/reset-2fa")
    }

    useEffect(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('loggedInUserDetails');
        
        const loginProcess = async () => {
            try {
                if (response?.access_token) {                    
                    localStorage.setItem('accessToken', response.access_token);
    
                    const userDetailsResponse = await dispatch(getLoggedInUserDetails());
                    if (userDetailsResponse?.access_type) {
                        localStorage.setItem('loggedInUserDetails', JSON.stringify(userDetailsResponse));
                        if (response.require_2fa) {
                            navigate("/auth/verify-account");
                        } else {
                            navigate("/organization"); // Adjust based on the actual route
                        }
                        setIsLoading(false);
                    }
                } else if (response) {
                    setIsLoading(false);
                    setLoginFailed(true);
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Error in login process:", error);
            }
        };
    
        loginProcess();
    }, [response, navigate, dispatch]);

    


    return (
        <div className='login-container'>
            <Row className='h-100 m-0'>
                <Col md="6">
                    <LeftSection />
                </Col>
                <Col md="6">
                    <div className='login-right-section px-4 mx-3 h-100'>
                        <div className='right-container'>
                            <h1 className='mb-4'>{t("loginPage.rightSection.login")}</h1>
                            <p className="description">{t("loginPage.rightSection.secondaryText")}</p>
                            {loginFailed && <p className="error-msg text-center mt-3">{response?.description}</p>}
                            <div className='input-container mt-3'>
                                <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={inputChange} inputValue={userName}/>
                                <InputBox placeholder={'Password'} inputType={'password'} inputLabel={'Password'} inputChange={inputChange} inputValue={password}/>
                            </div>
                            <div className='submit-button'>
                                {!isLoading && <Button buttonText={"Login"} buttonType="primary" buttonClicked={buttonClicked} disableButton={isDisabledButton()}/>}
                                {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true}/>}
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <div className='loggedin-checkbox'>
                                    <CheckBox inputLabel={'Keep me logged in'} index={0} checkBoxId={'0'} handleCheckboxChange={setCheckboxvalue} checkBoxValue={'keepLogin'} disable={false} />
                                </div>
                                <div className='forget-password'>
                                    <Button buttonText={"Forget Password?"} buttonType="link" buttonClicked={forgetPasswordClicked} disableButton={isDisabledButton()}/> / <Button buttonText={"Reset 2FA?"} buttonType="link" buttonClicked={reset2Fa} />
                                </div>
                            </div>
                            <div className='not-register mt-5'>
                                <div>
                                    <span>{t('loginPage.rightSection.notRegistered')} </span>
                                    <Button buttonText={"Create an Account"} buttonType="link" buttonClicked={buttonClicked} />
                                </div>
                            </div>
                        </div>
                        <RightFooter />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LoginPage;