import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import organizationReducer from "./organizationReducer";


const rootReducer = combineReducers({
    login: loginReducer,
    organization: organizationReducer,
    register: registerReducer,
});
  
export default rootReducer;