import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import tileViewIcon from "../../assets/images/common/tile-view.svg";
import listViewIcon from "../../assets/images/common/list-view.svg";


import SideNav from "../../components/PostLogin/SideNav";
import OrganizationTileView from '../../components/PostLogin/Organization/OrganizationTileView';
import OrganizationListView from "../../components/PostLogin/Organization/OrganizationListView";
import SideModal from '../../components/ui/SideModal';
import AddOrganizationModal from '../../components/PostLogin/Organization/AddOrganizationModel';
import Pagination from '../../components/ui/Pagination';
import Spinner from "../../components/ui/spinner";

import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationList } from '../../store/actions/organizationActions.js';

const Organization = () => {

    const organizationListResponse = useSelector((state) => state.organization?.organizationList);
    const deleteOrganizationResponse = useSelector((state)=> state.organization?.deleteOrganizationResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [viewType, setViewType] = useState('tile');
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }
    
    const searchOrganization = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setCurrentPage(1);
    }

    const fetchOrganizations = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${searchValue}`;
        setIsLoading(true);
        dispatch(getOrganizationList(url));
    },[searchValue, currentPage, currentPageSize, dispatch])
    

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const backToMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            setCurrentPage(1);
            setCurrentPageSize(10);
            fetchOrganizations()
        }
        closeAddOrgSidePanel()
    }

    useEffect(()=>{
        fetchOrganizations()
    },[fetchOrganizations])

    useEffect(() => {
        if(organizationListResponse!= null) {
            setIsLoading(false)
        }
    }, [organizationListResponse]);

    useEffect(()=>{
        if(deleteOrganizationResponse != null) {
            if(deleteOrganizationResponse?.detail?.status === "success") {
                setIsLoading(true)
                fetchOrganizations()
            }
        }
    },[deleteOrganizationResponse, fetchOrganizations])

   
    return (
        <div className="organization-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("organizationPage.heading")}</h1>
                    </div>
                    <div className="header-right">
                        <button onClick={()=>{setShowAddOrgSidePanel(true)}}><span>&#43;</span>{t("organizationPage.addOrganization")}</button>
                    </div>
                </div>
                <div className="page-search-header px-4 py-3  d-flex align-items-center justify-content-between">
                    <div className="left-search-container position-relative">
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        <input type='text' placeholder='Search Organization' className="search-input" value={searchValue} onChange={searchOrganization}/>
                    </div>
                    <div className="right-view-option d-flex">
                        <div className={`tile-view-image ${viewType === 'tile' ? 'active' : ''}`} onClick={()=>setViewType('tile')}>
                            <img src={tileViewIcon} alt="tile view" />
                        </div>
                        <div className={`list-view-image ${viewType === 'list' ? 'active' : ''}`} onClick={()=>setViewType('list')}>
                            <img src={listViewIcon} alt="list view" />
                        </div>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <h2>{t("organizationPage.pageHeading")}</h2>
                    {
                        isLoading ? 
                        <div className="d-flex align-items-center justify-content-center orgaization-loader">
                            <Spinner />
                        </div>
                        :
                        <>
                            {viewType === 'tile' && organizationListResponse?.results.length > 0 && <OrganizationTileView organizationList={organizationListResponse} />}
                            {viewType === 'list' && organizationListResponse?.results.length > 0 && <OrganizationListView organizationList={organizationListResponse} />}
                            {organizationListResponse != null && <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                pageSize={currentPageSize}
                                totalCount={organizationListResponse.meta.total_results}
                                onPageChange={setCurrentPageandRecordSize}
                            />}
                        </>
                    }
                    {
                        !isLoading && organizationListResponse?.meta?.total_results === 0 && 
                        <div className="d-flex justify-content-center align-items-center no-result">
                            <h2>No Result found</h2>
                        </div>
                    }                    
                </div>
            </div>
            <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                <AddOrganizationModal backToMainPage={backToMainPage}/>
            </SideModal>
        </div>
    )
}

export default Organization;