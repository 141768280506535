import { React, useState } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";


import arrowRight from "../../../../assets/images/common/arrow-right-border.svg";
import dummyAliImage from "../../../../assets/images/common/dummy-logo.png";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import Pagination from '../../../../components/ui/Pagination';


const DomainListView = () => {

    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);

    return (
        <Row>
            <Col md="12">
                <div className="domain-list-view-container organization-list-view-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t('domainPage.listView.domain')}</th>
                                <th>{t('domainPage.listView.scanStatus')}</th>
                                <th>{t('domainPage.listView.hackerScore')}</th>
                                <th>{t('domainPage.listView.riskRating')}</th>
                                <th>{t('domainPage.listView.totalFinding')}</th>
                                <th>{t('domainPage.listView.lastScan')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ProfileAvatar type="domain" name={"alibaba.com"} imageUrl={dummyAliImage} />
                                </td>
                                <td>
                                    <p>60%</p>
                                    <ProgressBar
                                        now={60}
                                        style={{ height: '8px', width: '100%' }}
                                    />
                                </td>
                                <td>
                                    <p>857</p>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.infrastructure")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.vulnerabilities")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.exposure")}</span>
                                                <span>B</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.darkWeb")}</span>
                                                <span>A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.assetDiscovery")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.DarkWeb")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.concerningExposures")}</span>
                                                <span>0</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <p>July 21,2024 05:48</p>
                                </td>
                                <td>
                                    <div className='organization-list-view'>
                                        <button className="view-details-btn-scan">
                                            <img src={arrowRight} alt='scan' />
                                            {t("domainPage.startScanBtn")}
                                        </button>
                                        <button className="view-scan-btn">
                                            {t("organizationPage.viewDetailsBtn")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="domain" name={"alibaba.com"} imageUrl={dummyAliImage} />
                                </td>
                                <td>
                                    <p>60%</p>
                                    <ProgressBar
                                        now={60}
                                        style={{ height: '8px', width: '100%' }}
                                    />
                                </td>
                                <td>
                                    <p>857</p>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.infrastructure")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.vulnerabilities")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.exposure")}</span>
                                                <span>B</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.darkWeb")}</span>
                                                <span>A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.assetDiscovery")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.DarkWeb")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.concerningExposures")}</span>
                                                <span>0</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <p>July 21,2024 05:48</p>
                                </td>
                                <td>
                                    <div className='organization-list-view'>
                                        <button className="view-details-btn-scan">
                                            <img src={arrowRight} alt='scan' />
                                            {t("domainPage.startScanBtn")}
                                        </button>
                                        <button className="view-scan-btn">
                                            {t("organizationPage.viewDetailsBtn")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="domain" name={"alibaba.com"} imageUrl={dummyAliImage} />

                                </td>
                                <td>
                                    <p>60%</p>
                                    <ProgressBar
                                        now={60}
                                        style={{ height: '8px', width: '100%' }}
                                    />
                                </td>
                                <td>
                                    <p>857</p>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.infrastructure")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.vulnerabilities")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.exposure")}</span>
                                                <span>B</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.darkWeb")}</span>
                                                <span>A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.assetDiscovery")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.DarkWeb")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.concerningExposures")}</span>
                                                <span>0</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <p>July 21,2024 05:48</p>
                                </td>
                                <td>
                                    <div className='organization-list-view'>
                                        <button className="view-details-btn-scan">
                                            <img src={arrowRight} alt='scan' />
                                            {t("domainPage.startScanBtn")}
                                        </button>
                                        <button className="view-scan-btn">
                                            {t("organizationPage.viewDetailsBtn")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="domain" name={"alibaba.com"} imageUrl={dummyAliImage} />

                                </td>
                                <td>
                                    <p>60%</p>
                                    <ProgressBar
                                        now={60}
                                        style={{ height: '8px', width: '100%' }}
                                    />
                                </td>
                                <td>
                                    <p>857</p>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.infrastructure")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.vulnerabilities")}</span>
                                                <span>A+</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.exposure")}</span>
                                                <span>B</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("domainPage.darkWeb")}</span>
                                                <span>A</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="finding-list">
                                        <ul type="none" className="m-0 p-0">
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.assetDiscovery")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.DarkWeb")}</span>
                                                <span>0</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>{t("organizationPage.concerningExposures")}</span>
                                                <span>0</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <p>July 21,2024 05:48</p>
                                </td>
                                <td>
                                    <div className='organization-list-view'>
                                        <button className="view-details-btn-scan">
                                            <img src={arrowRight} alt='scan' />
                                            {t("domainPage.startScanBtn")}
                                        </button>
                                        <button className="view-scan-btn">
                                            {t("organizationPage.viewDetailsBtn")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={100}
                        pageSize={10}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default DomainListView;