import { React } from 'react';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";

import Delete from "../../../../assets/images/common/delete-session.svg";


const UserSessionTab = () => {

    const { t } = useTranslation()
    
    
    return (
        <>
            <div className="active-session-container">
                <div className="active-session-tabel">
                    <div className="list-view-container user-session-list p-3">
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>{t("accountSettingPage.userSessionTab.userAgent")}</th>
                                    <th>{t("accountSettingPage.userSessionTab.lastAccessed")}</th>
                                    <th>{t("accountSettingPage.userSessionTab.lastLogin")}</th>
                                    <th>{t("accountSettingPage.userSessionTab.ipAddress")}</th>
                                    <th>{t("accountSettingPage.userSessionTab.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7 <span>CURRENT SESSION</span></td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                                <tr>
                                    <td className="system-name">Chrome 126 on Mac OS X 10.5.7</td>
                                    <td>3 hours Ago</td>
                                    <td>July 21, 2024 05:48</td>
                                    <td>
                                        <p>2401:4900:883b:551b:f4a5:b7a7:8e5e:c099</p>
                                        <p>Bharathi Airtel</p>
                                    </td>
                                    <td><img src={Delete} alt="delete session"/></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserSessionTab;