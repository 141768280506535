import { React, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";


import InputBox from "../../../ui/InputBox";
import Button from "../../../ui/Button";

import { useSelector, useDispatch } from 'react-redux';
import { addDomain, resetAddDomainResponse } from '../../../../store/actions/organizationActions.js';


const AddDomainModal = ({closeAddDomain}) => {

    const addDomainResponse = useSelector((state) => state.organization?.addDomainResponse);

    const dispatch = useDispatch();

    const { organizationId } = useParams();

    const { t } = useTranslation();

    const [domainName,setDomainName] = useState([]);
    const [addDomainSuccess,setAddDomainSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const inputChange = (val) => {
        setDomainName(val);
    }

    const goBack = () => {
        dispatch(resetAddDomainResponse())
        closeAddDomain()
    }

    const saveDomain = () =>{
        let obj = {
            domain:domainName,
            organization_id:organizationId
        }
        setIsLoading(true)
        dispatch(addDomain(obj))
    }

    useEffect(()=>{
        if(addDomainResponse != null) {
            setAddDomainSuccess(true)
        }
    },[addDomainResponse, addDomainSuccess])

    return (
        <div className="add-organization-container add-domain-container">
            {!addDomainSuccess && <>
                <div className="modal-header mt-4">
                    <h2>{t("organizationDetailsPage.addDomain.addDomain")}</h2>
                </div>
                <div className="add-organization-step-one mt-3">
                    <div className="input-container mt-4">
                        <InputBox placeholder={'Enter Domain name'} inputType={'text'} inputLabel={'What is the Name of your Domain?'} inputChange={inputChange} inputValue={domainName}/>
                    </div>
                </div>
                <div className="form-submit-btn-group mt-4">
                    <Button buttonText={"Cancel"} buttonType="secondary" />
                    {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{saveDomain()}} disableButton={domainName === ""}/>}
                    {isLoading && <Button buttonText={"Loading"} buttonType="primary" disableButton={true}/>}
                </div>
            </>}
            {addDomainSuccess && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    {addDomainResponse?.detail?.status === "success" && <>
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{t("organizationDetailsPage.addDomain.domainAdded")}</h2>
                        <p>{t("organizationDetailsPage.addDomain.successMsg")}</p>
                        <div className="btn-container mt-5">
                            <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                        </div>
                    </>}
                    {addDomainResponse?.status === "failed" && <>
                        <div className="mt-5">
                            <img src={failed} alt="failed" />
                        </div>
                        <h2 className="mb-4 mt-5">{addDomainResponse?.description}</h2>
                        <div className="btn-container mt-5">
                            <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                        </div>
                    </>}
                </div>
            </>}
        </div>
    )
}

export default AddDomainModal;