import { React } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "./index.scss"

import cybermindrLogo from "../../../assets/images/common/cybermindr_logo.svg";
import OrganizationImage from "../../../assets/images/side-nav/organization.svg";
import domain from "../../../assets/images/side-nav/domains.svg";
import scanInfo from "../../../assets/images/side-nav/scan-info.svg";
import Users from "../../../assets/images/side-nav/users.svg";
import monitor from "../../../assets/images/side-nav/monitor.svg";
import scanQueue from "../../../assets/images/side-nav/scan_queue.svg";
// import accountSetting from "../../../assets/images/side-nav/account-setting.svg";

import LanguageSwitcher from '../../../components/LanguageSwitcher';
import SideNavProfile from "../SideNavProfile";


const SideNav = () => {
    const { t } = useTranslation();
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    return (
        <div className="side-nav-container pt-3">
            <div className="main-logo-container text-center">
                <img src={cybermindrLogo} alt="logo" />
            </div>
            <div className="side-nav-list">
                <ul type="none">
                    <li className={splitLocation[1] === "organization" || splitLocation[1] === "organizationdetails" ? "active" : ""}>
                        <img src={OrganizationImage} alt="orgaization"/>
                        <Link to="/organization">{t("sideNav.nav1")}</Link>
                    </li>
                    <li className={splitLocation[1] === "domain" ? "active" : ""}>
                        <img src={domain} alt="domain"/>
                        <Link to="/domain">{t("sideNav.nav2")}</Link>
                    </li>
                    <li className={splitLocation[1] === "scan-info" ? "active" : ""}>
                        <img src={scanInfo} alt="scanInfo"/>
                        <Link to="/scan-info/exposure/dashboard">{t("sideNav.nav3")}</Link>
                    </li>
                    <li className={splitLocation[1] === "users" ? "active" : ""}>
                        <img src={Users} alt="Users"/>
                        <Link to="/users">{t("sideNav.nav4")}</Link>
                    </li>
                    <li className={splitLocation[1] === "monitor" ? "active" : ""}>
                        <img src={monitor} alt="monitor"/>
                        <Link to="/monitor">{t("sideNav.nav5")}</Link>
                    </li>
                    <li className={splitLocation[1] === "scan-queue" ? "active" : ""}>
                        <img src={scanQueue} alt="scanQueue"/>
                        <Link to="/scan-queue">{t("sideNav.nav6")}</Link>
                    </li>
                </ul>
                <div className="bottom-nav-list mt-5">
                    {/* <div className={`account-setting ${splitLocation[1] === "account" ? "active" : ""}`}>
                        <Link to="/account/settings"><img src={accountSetting} alt="account-setting"/></Link>
                    </div> */}
                    <LanguageSwitcher />
                    <SideNavProfile />
                </div>
            </div>
        </div>
    )
}

export default SideNav;