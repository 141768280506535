import React from 'react';
// import { useTranslation } from "react-i18next";
import "./index.scss";

import ScanInfo from "../../../../ScanInfo";

const SensitiveExposure = () => {
    return (
        <ScanInfo>
            <h1>SensitiveExposure page</h1>
        </ScanInfo>
        
    )
}


export default SensitiveExposure;