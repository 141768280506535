import React from 'react';
import pointer from "../../../../assets/images/domain/pointer.svg";
import { Row, Col } from 'react-bootstrap';
import styles from './Gauge.module.scss';

const Gauge = () => {
  const value = 75; // Adjust the value to control the gauge fill percentage within 270 degrees

  return (
    <Row className="align-items-center border-bottom mt-3">
      <Col xs="auto">
        <div className={styles.gaugeContainer}>
          <div className={styles.gauge}>
            <div className={styles.innerCircle}></div>
            {/* Rounded start cap */}
            <div className={styles.cap}></div>
            {/* Rounded end cap */}
            <div
              className={styles.cap_ot}
              style={{ transform: `rotate(${value * 2.7}deg)` }}
            ></div>
          </div>
          <div className={styles.gaugeTextPro}>
            <img src={pointer} alt="riskRating" />
          </div>
        </div>
      </Col>
      <Col xs="auto" className='hacker-sec mb-2'>
        <h5>900</h5>
        <p>Hacker Score</p>
      </Col>
    </Row>
  );
};

export default Gauge;
