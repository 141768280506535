import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import criticalIcon from "../../assets/images/organization/critical.svg";
import highIcon from "../../assets/images/organization/high.svg";
import mediumIcon from "../../assets/images/organization/medium.svg";
import lowIcon from "../../assets/images/organization/low.svg";
import portfolioIcon from "../../assets/images/organization/portfolio.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import Spinner from "../../components/ui/spinner";
import DomainTab from "../../components/PostLogin/OrganizationDetails/DomainTab";
import UsersTab from "../../components/PostLogin/OrganizationDetails/UsersTab";
import DeleteOrganizationModal from "../../components/PostLogin/OrganizationDetails/DeleteOrganizationModal";
import SideModal from '../../components/ui/SideModal';
import AddOrganizationModal from '../../components/PostLogin/Organization/AddOrganizationModel';

import { formatDate, formatNumber } from "../../utils/util.js";

import { useSelector, useDispatch } from 'react-redux';
import { getOrganizationDetails, resetOrganizationDetail, resetDeleteOrganizationResponse, resetOrganizationListResponse } from '../../store/actions/organizationActions.js';

const OrganizationDetails = () => {

    const organizationDetailResponse = useSelector((state) => state.organization?.organizationDetail);
    const deleteOrganizationResponse = useSelector((state)=> state.organization?.deleteOrganizationResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { organizationId } = useParams();

    const [activeTab, setActiveTab] = useState('domain');
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const backToMainPage = (data) => {
        if(data?.detail?.status === "success") {
            setIsLoading(true)
            fetchOrganizationsDetails()
        }
        closeAddOrgSidePanel()
    }

    const showModal = () => {
        setModalShow(true);
    };

    const fetchOrganizationsDetails = useCallback(()=>{
        let obj = {
            organization_id: organizationId
        }
        setIsLoading(true);
        dispatch(getOrganizationDetails(obj));
    },[dispatch, organizationId])


    const backToOrganizationPage = () => {
        dispatch(resetOrganizationDetail())
        navigate(`/organization`)
    }

    useEffect(()=>{
        fetchOrganizationsDetails()
    },[fetchOrganizationsDetails])

    useEffect(()=>{
        if(organizationDetailResponse != null){
            setIsLoading(false);
        }
    },[organizationDetailResponse])

    useEffect(()=>{
        if(deleteOrganizationResponse != null) {
            if(deleteOrganizationResponse?.detail?.status === "success") {
                dispatch(resetOrganizationListResponse())
                setModalShow(false)
                navigate(`/organization`)
            }
            dispatch(resetDeleteOrganizationResponse())
        }
    },[deleteOrganizationResponse, dispatch, navigate])


    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1 onClick={()=>{backToOrganizationPage()}}>{t("organizationPage.heading")}</h1>
                        <img src={arrowRight} alt="arrow-right"/>
                        {organizationDetailResponse !== null && <ProfileAvatar type="domain" name={organizationDetailResponse?.name} imageUrl={organizationDetailResponse?.logo} />}
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button onClick={()=>{setShowAddOrgSidePanel(true)}}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.editBtn")}
                        </button>
                        <button onClick={() => showModal()}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
                { isLoading ?
                    <div className="d-flex align-items-center justify-content-center orgaization-loader">
                        <Spinner />
                    </div>
                    :
                    <>
                        {<div className="page-main-section p-4">
                            <div className="organization-created d-flex justify-content-between">
                                <h4>{t("organizationDetailsPage.heading")}</h4>
                                <ProfileAvatar pageType={"organizationDetails"} type="profile" firstName={organizationDetailResponse?.created_by?.name} lastName={""} imageUrl={organizationDetailResponse?.created_by?.avatar} />
                            </div>
                            <div className="organization-header-container p-3 my-3">
                                <div className="d-flex justify-content-between">
                                    <div className="header-details d-flex gap-3">
                                        <p>{t("organizationDetailsPage.createdOn")} <span>{formatDate(organizationDetailResponse?.created_at)}</span></p>
                                        {organizationDetailResponse?.updated_at !== null && <p>{t("organizationDetailsPage.updatedOn")} <span>{formatDate(organizationDetailResponse?.updated_at)}</span></p>}
                                    </div>
                                    <div className="organization-status d-flex gap-3">
                                        {organizationDetailResponse?.business_impact === "critical" && <p className="crictical">{t("organizationPage.BusinessImpact")} <span><img src={criticalIcon} alt="critical" />{organizationDetailResponse?.business_impact}</span></p>}
                                        {organizationDetailResponse?.business_impact === "high" && <p className="high">{t("organizationPage.BusinessImpact")} <span><img src={highIcon} alt="critical" />{organizationDetailResponse?.business_impact}</span></p>}
                                        {organizationDetailResponse?.business_impact === "medium" && <p className="medium">{t("organizationPage.BusinessImpact")} <span><img src={mediumIcon} alt="critical" />{organizationDetailResponse?.business_impact}</span></p>}
                                        {organizationDetailResponse?.business_impact === "low" && <p className="low">{t("organizationPage.BusinessImpact")} <span><img src={lowIcon} alt="critical" />{organizationDetailResponse?.business_impact}</span></p>}
                                        <p className="porfolio">{t("organizationPage.BusinessType")} <span><img src={portfolioIcon} alt="critical" />{organizationDetailResponse?.type}</span></p>
                                    </div>
                                </div>
                                <div className="d-flex gap-3 mt-3">
                                    <div className="hacker-score-container p-3 rounded text-center">
                                        <p className="hacker-score">{formatNumber(organizationDetailResponse?.stats?.overall_score?.obtained_score)}<span>/{organizationDetailResponse?.stats?.overall_score?.max_score}</span></p>
                                        <p className="hacker-text">{t("organizationDetailsPage.latestHackerScore")}</p>
                                    </div>
                                    <p className="border-right mx-2"></p>
                                    <div className="total-domains-container rounded">
                                        <p className="total-domain">{organizationDetailResponse?.stats?.domains?.total}</p>
                                        <p className="domain-text">{t("organizationDetailsPage.totalDomain")}</p>
                                    </div>
                                    <div className="total-score-container rounded">
                                        <div className="users-totol-count d-flex">
                                            <p className="total-user-count">{organizationDetailResponse?.stats?.users?.total}</p>
                                            <div className="user-count mx-3 mt-2">
                                                <span className="active d-inline-block mx-1"></span>
                                                <span className="disbled d-inline-block"></span>
                                                <p>{t("organizationDetailsPage.totalUser")}</p>
                                            </div>
                                        </div>
                                        <div className="active-disabled-count d-flex gap-3">
                                            <p className="active-user"><span className="d-inline-block">{organizationDetailResponse?.stats?.users?.active}</span> {t("organizationDetailsPage.activeUser")}</p>
                                            <p className="disabled-user"><span className="d-inline-block">{organizationDetailResponse?.stats?.users?.disabled}</span> {t("organizationDetailsPage.disabledUser")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="organization-main-container p-3">
                                <div className="orgs-tab-container d-flex rounded">
                                    <p className={`rounded text-center ${activeTab === "domain" ? "active" : ""}`} onClick={()=>setActiveTab("domain")}>{t("organizationDetailsPage.DomainTab")}</p>
                                    <p className={`rounded text-center ${activeTab === "user" ? "active" : ""}`} onClick={()=>setActiveTab("user")}>{t("organizationDetailsPage.userTab")}</p>
                                </div>
                                {activeTab === "domain" && <DomainTab />}
                                {activeTab === "user" && <UsersTab />}
                            </div>
                            <DeleteOrganizationModal show={modalShow} organizationDetail={organizationDetailResponse} onHide={() => setModalShow(false)} />
                        </div>}
                    </>
                }
                <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                    <AddOrganizationModal backToMainPage={backToMainPage} type={"edit"} organizationDetail={organizationDetailResponse!== null ? organizationDetailResponse : ""} showAddOrgSidePanel={showAddOrgSidePanel} />
                </SideModal>
            </div>
        </div>
    )
}

export default OrganizationDetails;