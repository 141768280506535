import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import arrowRight from "../../assets/images/common/arrow-right.svg";
import OrganizationDummyImage from "../../assets/images/common/organization-dummy-image1.png";
import editIcon from "../../assets/images/common/edit-icon.svg";
import deleteIcon from "../../assets/images/common/delete-icon.svg";
import dummyProfile from "../../assets/images/side-nav/dummy-profile.png";
import scannerIcon from "../../assets/images/common/scanner.svg";
import criticalIcon from "../../assets/images/organization/critical.svg";
import portfolioIcon from "../../assets/images/organization/portfolio.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import DomainTab from "../../components/PostLogin/DomainDetails/DomainTab";
import AssetsTab from "../../components/PostLogin/DomainDetails/AssetsTab";
import DeleteDomainModal from "../../components/PostLogin/Domain/DeleteDomainModal";
import SideModal from '../../components/ui/SideModal';
import ScanProgressModal from '../../components/PostLogin/DomainDetails/ScanProgressModal';
import EditDomainModal from '../../components/PostLogin/Domain/EditDomainModal'; // Import the EditDomainModal

const DomainDetails = () => {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('domain');
    const [modalShow, setModalShow] = useState(false);
    const [showScanProgressModal, setShowScanProgressModal] = useState(false);
    const [scanningDomainId, setScanningDomainId] = useState(1);
    const [pausedDomainId, setPausedDomainId] = useState(null);
    const [showAddOrgSidePanel, setShowAddOrgSidePanel] = useState(false);

    const closeAddOrgSidePanel = () => {
        setShowAddOrgSidePanel(false)
    }

    const showModal = () => {
        setModalShow(true);
    };

    const openScanProgressModal = () => {
        setShowScanProgressModal(true);
    };

    const closeScanProgressModal = () => {
        setShowScanProgressModal(false);
    };

    const handlePauseResume = (id) => {
        setPausedDomainId((prev) => (prev === id ? null : id));
    };

    useEffect(() => { }, []);

    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1>{t("domainPage.heading")}</h1>
                        <img src={arrowRight} alt="arrow-right" />
                        <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                    </div>
                    <div className="header-right d-flex gap-3">
                        <button className='viewScan-btn' onClick={openScanProgressModal}>
                            <img src={scannerIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.viewScanBtn")}
                        </button>
                        <button onClick={() => { setShowAddOrgSidePanel(true) }}> {/* Edit button to open modal */}
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.editBtn")}
                        </button>
                        <button onClick={() => showModal()}>
                            <img src={deleteIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
                <div className="page-main-section p-4">
                    <div className="organization-created d-flex justify-content-between">
                        <h4>{t("domainDetailsPage.heading")}</h4>
                        <ProfileAvatar pageType={"organizationDetails"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                    </div>
                    <div className="organization-header-container p-3 my-3">
                        <div className="d-flex justify-content-between">
                            <div className="header-details d-flex gap-3">
                                <p>{t("organizationDetailsPage.createdOn")} <span>July 21, 2004 5:48</span></p>
                                <p>{t("organizationDetailsPage.updatedOn")} <span>July 21, 2004 5:48</span></p>
                                <p>{t("domainDetailsPage.lastScanOn")} <span>July 21, 2004 5:48</span></p>
                            </div>
                        </div>
                        <div className="d-flex gap-3 mt-3">
                            <div className="hacker-score-container p-3 rounded text-center">
                                <p className="hacker-score">846<span>{t("organizationDetailsPage.scoreTotal")}</span></p>
                                <p className="hacker-text">{t("organizationDetailsPage.latestHackerScore")}</p>
                            </div>
                            <p className="border-right mx-2"></p>
                            <div className="total-score-container rounded">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">50</p>
                                    <div className="user-count mx-3 mt-2">
                                        <span className="active-icon d-inline-block mx-1"></span>
                                        <span className="passive-icon d-inline-block"></span>
                                        <p>{t("domainPage.totalScans")}</p>
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p><span className="count d-inline-block">48</span> {t("domainPage.activeScans")}</p>
                                    <p><span className="count-passive d-inline-block">02</span> {t("domainPage.passiveScans")}</p>
                                </div>
                            </div>
                            <div className="total-domains-container rounded">
                                <p className="total-domain">100</p>
                                <p className="domain-text">{t("organizationDetailsPage.totalDomain")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="organization-main-container p-3">
                        <div className="orgs-tab-container d-flex rounded">
                            <p className={`rounded text-center ${activeTab === "domain" ? "active" : ""}`} onClick={() => setActiveTab("domain")}>{t("domainDetailsPage.scanTab")}</p>
                            <p className={`rounded text-center ${activeTab === "assets" ? "active" : ""}`} onClick={() => setActiveTab("assets")}>{t("domainDetailsPage.assetTab")}</p>
                        </div>
                        {activeTab === "domain" && <DomainTab />}
                        {activeTab === "assets" && <AssetsTab />}
                    </div>
                    <DeleteDomainModal show={modalShow} onHide={() => setModalShow(false)} />
                    {showScanProgressModal && (
                        <ScanProgressModal
                            show={showScanProgressModal}
                            onHide={closeScanProgressModal}
                            scanningDomainId={scanningDomainId}
                            pausedDomainId={pausedDomainId}
                            handlePauseResume={handlePauseResume}
                        />
                    )}
                    <SideModal showSidePanel={showAddOrgSidePanel} hideSidePanel={closeAddOrgSidePanel}>
                        <EditDomainModal />
                    </SideModal>
                </div>
            </div>
        </div>
    );
};

export default DomainDetails;
