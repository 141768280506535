import React,{ useState } from 'react';
// import { useTranslation } from "react-i18next";
import "./index.scss";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// import ArrowRight from "../../../../assets/images/scan-info/arrow-right.svg";
import dummyProfile from "../../../../assets/images/side-nav/dummy-profile.png";

import DropDown from '../../../../components/ui/DropDown';


const Header = () => {

    const [selectedOrganization, setSelectedOrganization] = useState("");

    const [selectedDate, setSelectedDate] = useState(null);

    const organizationList = [
        {
            label:"Cyberminde",
            name: "Cyberminde",
            value:"cybermindr",
            id: "cybermindr",
            logo: dummyProfile,
        },
        {
            label:"wati",
            name: "wati",
            value:"wati", 
            id: "wati",           
            logo: dummyProfile,
        },
        {
            label:"lollypop",
            name: "lollypop",
            value:"lollypop",
            id: "lollypop",       
            logo: dummyProfile,
        }
    ]

    const handleSelectOrganization = (value) => {
        setSelectedOrganization(value)
    }

    const availableDates = [
        new Date(2024, 9, 15),
        new Date(2024, 9, 18), 
        new Date(2024, 8, 11),
        new Date(2024, 7, 5),
    ];

    const isAvailableDate = (date) => {
        return availableDates.some(
            (availableDate) => availableDate.toDateString() === date.toDateString()
        );
    };

    // Highlight available dates
    const highlightAvailableDates = (date) => {
        return isAvailableDate(date) ? "highlighted-date" : undefined;
    };

    

    const CalenderIcon = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.66663 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3334 1.6665V4.1665" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91663 7.57471H17.0833" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08317V14.1665C17.5 16.6665 16.25 18.3332 13.3333 18.3332H6.66667C3.75 18.3332 2.5 16.6665 2.5 14.1665V7.08317C2.5 4.58317 3.75 2.9165 6.66667 2.9165H13.3333C16.25 2.9165 17.5 4.58317 17.5 7.08317Z" stroke="#00308B" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 11.4167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.079 13.9167H13.0864" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 11.4167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9962 13.9167H10.0037" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 11.4167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91197 13.9167H6.91945" stroke="#00308B" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
    }

    const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            ref={ref}
            value={value}
            onClick={onClick}
            readOnly // Make the input non-editable
            className="form-control"
            placeholder="Select a date"
        />
    ));

    return (
        <>
            <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                <div className="header-left d-flex align-items-center gap-3">
                    <h1>Domain Info</h1>
                </div>
            </div>
            <div className="page-header-container px-4 py-3">
                <div className="d-flex gap-3 justify-content-between align-items-center">
                    <div className="d-flex switch-container">
                        <div className="switch-organization">
                            <p>Switch Organization</p>
                            <DropDown
                                options={organizationList}
                                placeHolder='Please select Organization'
                                onChange={(e) => handleSelectOrganization(e)}
                                isSearchable = {true}
                                dropDownType = {'scanInfo'}
                            />
                        </div>
                        <div className="border-between"></div>
                        <div className="switch-organization">
                            <p>Switch Domain</p>
                            <DropDown
                                options={organizationList}
                                placeHolder='Please select Domain'
                                onChange={(e) => handleSelectOrganization(e)}
                                isSearchable = {true}
                                dropDownType = {'scanInfo'}
                            />
                        </div>
                    </div>
                    <div className="scan-info-datepicker">
                        <DatePicker
                            showIcon
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            filterDate={isAvailableDate} // Enable only available dates
                            dayClassName={highlightAvailableDates} // Highlight available dates
                            placeholderText="Select an date"
                            icon={CalenderIcon()}
                            dateFormat="MMM dd, yyyy"
                            customInput={<CustomDateInput />}
                            maxDate={new Date()}
                            className="custom-width-datepicker"
                        >
                            <div className="additional-content">
                                <div className="selected-date"><span></span>Selected Date</div>
                                <div className="scanned-date"><span></span>Scanned Date</div>
                            </div>                                    
                        </DatePicker>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;