// ScanModal.js
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import Button from "../../../../components/ui/Button";
import FileUpload from "../../../../components/ui/FileUpload";
import InputBox from "../../../../components/ui/InputBox";
import RadioButton from "../../../../components/ui/RadioButton";
import "./index.scss";
import domainIcon from "../../../../assets/images/common/domain.svg";

const ScanModal = (props) => {
    const { t } = useTranslation();

    // State variables
    const [organizationName, setOrganizationName] = useState('');
    const [scanType, setScanType] = useState('active');  // Default to "active"
    const [scanSpeed, setScanSpeed] = useState('slow');   // Default to "slow"
    const [subdomainList, setSubdomainList] = useState('');
    const [ipAddressList, setIpAddressList] = useState('');
    const [emailList, setEmailList] = useState('');

    // Handlers
    const scanTypeChange = (e) => {
        if (e?.target?.value) setScanType(e.target.value);
    };
    
    const scanSpeedChange = (e) => {
        if (e?.target?.value) setScanSpeed(e.target.value);
    };

    const handleInputChange = (setter) => (e) => {
        if (e?.target?.value !== undefined) setter(e.target.value);
    };

    const handleDelete = () => {
        if (organizationName && scanType && scanSpeed) {
            // Add actual delete logic here
            props.onHide();
        } else {
            alert("Please fill in all fields");
        }
    };

    return (
        <Modal {...props} centered className="delete-organization-modal">
            <Modal.Body>
                <div className="delete-organization-container">
                    <h4>{t("domainPage.scanModal.heading")}</h4>
                    <div className="add-organization-step-one">
                        <div className="organization-type">
                            <p>{t("domainPage.scanModal.selectScanType")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="active"
                                    radioLabel="Active"
                                    radioValue="active"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'active'}
                                />
                                <RadioButton
                                    radioId="passive"
                                    radioLabel="Passive"
                                    radioValue="passive"
                                    onChange={scanTypeChange}
                                    isChecked={scanType === 'passive'}
                                />
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>{t("domainPage.scanModal.selectScanSpeed")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton
                                    radioId="slow"
                                    radioLabel="Slow"
                                    radioValue="slow"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'slow'}
                                />
                                <RadioButton
                                    radioId="moderate"
                                    radioLabel="Moderate"
                                    radioValue="moderate"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'moderate'}
                                />
                                <RadioButton
                                    radioId="fast"
                                    radioLabel="Fast"
                                    radioValue="fast"
                                    onChange={scanSpeedChange}
                                    isChecked={scanSpeed === 'fast'}
                                />
                            </div>
                        </div>
                        <div className="input-container mt-4">
                            <InputBox
                                placeholder="Enter Subdomains"
                                inputType="text"
                                inputLabel="Additional Subdomain List"
                                inputChange={handleInputChange(setSubdomainList)}
                                inputValue={subdomainList}
                            />
                        </div>
                        <div className="input-container mt-2">
                            <InputBox
                                placeholder="Enter IP Address"
                                inputType="text"
                                inputLabel="Additional IP Address List"
                                inputChange={handleInputChange(setIpAddressList)}
                                inputValue={ipAddressList}
                            />
                        </div>
                        <div className="input-container mt-2">
                            <InputBox
                                placeholder="Enter Emails"
                                inputType="text"
                                inputLabel="Additional Email List"
                                inputChange={handleInputChange(setEmailList)}
                                inputValue={emailList}
                            />
                        </div>
                        <div className="form-submit-btn-group d-flex gap-3">
                            <Button buttonText="Cancel" buttonType="secondary" buttonClicked={props.onHide} />
                            <Button buttonText="Save" buttonType="primary" className="save-btn" buttonClicked={handleDelete} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ScanModal;
