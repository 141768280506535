import { React, useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";


import EditIcon from "../../../../assets/images/common/edit-icon.svg";
import dummyProfile from "../../../../assets/images/side-nav/dummy-profile.png";
import DeleteIcon from "../../../../assets/images/common/delete.svg";
import GalleryIcon from "../../../../assets/images/common/gallery-add.svg";
import CopyIcon from "../../../../assets/images/account-settings/copy-icon.svg";
import RefreshIcon from "../../../../assets/images/account-settings/refersh-icon.svg";
import tickCircleGreen from "../../../../assets/images/common/tick-circle-green.svg";
import tickCircle from "../../../../assets/images/common/tick-circle.svg";

import InputBox from "../../../../components/ui/InputBox";
import DropDown from '../../../../components/ui/DropDown';
import Button from "../../../../components/ui/Button";
import Setup2FaModal from "../Setup2FaModal";
import UpdatePasswordModal from "../UpdatePasswordModal";


const UserDetailsTab = () => {

    const { t } = useTranslation()

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [selectedTimeZone, setSelectedtimeZone] = useState("");
    const [profileEdit, setProfileEdit] = useState(false);
    const [loginEdit, setLoginEdit] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isEnableNotificationActive, setIsEnableNotificationActive] = useState(false);
    const [is2FaActive, setIs2FaActive] = useState(false);
    const [isOtherSettingEdit, setIsOtherSettingEdit] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const [passwordStrength, setPasswordStrenth] = useState('');
    const [lowerCasePassed, setLowerCasePassed] = useState(false);
    const [upperCasePassed, setUpperCasePassed] = useState(false);
    const [numberPassed, setNumberPassed] = useState(false);
    const [totalChracterPassed, setTotalChracterPassed] = useState(false);
    const [specialChracterPassed, setspecialChracterPassed] = useState(false);
    const [isPasswordNotSame, setIsPasswordNotSame] = useState(false);
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);


    const inputChange = (val, type, label) => {
        if (label === "New Password") {
            validatePassword(val)
        } else if(label === "Confirm Password") {
            setConfirmPassword(val)
        } else {
            setFirstName(val)
        }
    }

    const validatePassword = (val) => {
        setNewPassword(val)

        let lowerCase = /[a-z]/g;
        let upperCase = /[A-Z]/g;
        let numbers = /[0-9]/g;
        let special = /(?=.*[!@#$%^&*])/g;

        setLowerCasePassed(lowerCase.test(val) ? true : false);
        setUpperCasePassed(upperCase.test(val) ? true : false);
        setNumberPassed(numbers.test(val) ? true : false);
        setTotalChracterPassed(val.length > 7 ? true : false);
        setspecialChracterPassed(special.test(val) ? true : false);
    }

    const updatePasswordStrength = useCallback(() => {
        const validated = [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed];
        const passwordStrength = validated.filter((data) => data === true);
    
        if (passwordStrength.length > 0 && passwordStrength.length < 3) {
            setPasswordStrenth('weak');
        } else if (passwordStrength.length === 3 || passwordStrength.length === 4) {
            setPasswordStrenth('medium');
        } else if (passwordStrength.length === 5) {
            setPasswordStrenth('strong');
        } else {
            setPasswordStrenth('');
        }
    }, [lowerCasePassed, upperCasePassed, numberPassed, totalChracterPassed, specialChracterPassed]);


    const saveLoginDetils = () => {
        if(newPassword !== confirmPassword) {
            setIsPasswordNotSame(true)
        } else {
            // let obj = {
            //     user_id: userId,
            //     new_password: newPassword,
            //     confirm_password: confirmPassword
            // }
            setShowUpdatePasswordModal(true)
            // setIsLoading(true)
        }
    }

    const timeZoneList = [
        {
            label:"Asia/Kolkata",
            value:"asia/kolkata"
        },
        {
            label:"Asia/Delhi",
            value:"asia/delhi"
        },
        {
            label:"Asia/Chennai",
            value:"asia/chennai"
        }
    ]

    const handleSelectUser = (value) => {
        setSelectedtimeZone(value)
    }

    const activate2Fa = () => {
        setIs2FaActive(!is2FaActive);
        setModalShow(true);
    }

    useEffect(()=>{
        updatePasswordStrength()

    },[updatePasswordStrength])


    return (
        <>                
            <div className="profile-details-container">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.profileDetails")}</h2>
                    <p onClick={()=>{setProfileEdit(!profileEdit)}}><img src={EditIcon} alt="edit-profile"/>{t("accountSettingPage.userDetailsTab.edit")}</p>
                </div>
                {!profileEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="profile-image-section d-flex justify-content-between">
                        <div className="profile-image">
                            <p>{t("accountSettingPage.userDetailsTab.avatar")}</p>
                            <div className="image mt-3">
                                <img src={dummyProfile} alt="profile"/>
                            </div>
                        </div>
                        <div className="login-time">
                            <p>{t("accountSettingPage.userDetailsTab.lastLogin")} <span>August 1, 2024 11:44 AM</span></p>
                        </div>
                    </div>
                    <div className="details-section mt-5">
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.firstName")}</p>
                                    <p className="value">Benjamin</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.lastName")}</p>
                                    <p className="value">Doe</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.timeZone")}</p>
                                    <p className="value">Asia/Kolkata</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.enableNotification")}</p>
                                    <div className="switch-outer position-relative">
                                        <span></span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {profileEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="profile-image-section d-flex justify-content-between">
                        <div className="profile-image d-flex align-items-center">
                            <div>
                                <p>{t("accountSettingPage.userDetailsTab.avatar")}</p>
                                <div className="image mt-3">
                                    <img src={dummyProfile} alt="profile"/>
                                </div>
                            </div>
                            <div className="delete-icon">
                                <img src={DeleteIcon} alt="delete-icon" />
                            </div>
                            <div className="upload-button">
                                <button><img src={GalleryIcon} alt="gallery"/>{t("accountSettingPage.userDetailsTab.uploadImage")}</button>
                                <p>{t("accountSettingPage.userDetailsTab.uploadFormat")}</p>
                            </div>
                        </div>
                        <div className="login-time">
                            <p>{t("accountSettingPage.userDetailsTab.lastLogin")} <span>August 1, 2024 11:44 AM</span></p>
                        </div>
                    </div>
                    <div className="details-section mt-5">
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'First Name'} inputType={'text'} inputLabel={'First Name'} inputChange={inputChange} inputValue={firstName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Last Name'} inputType={'text'} inputLabel={'Lasst Name'} inputChange={inputChange} inputValue={lastName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details time-zone-width">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.timeZone")}</p>
                                    <DropDown
                                        options={timeZoneList}
                                        placeHolder='Please select time zone'
                                        onChange={(e) => handleSelectUser(e)}
                                        isSearchable = {false}
                                    />
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.enableNotification")}</p>
                                </div>
                                <div className={`switch-outer position-relative edit-mode ${isEnableNotificationActive ? "active" : ""}`} onClick={()=>{setIsEnableNotificationActive(!isEnableNotificationActive)}}>
                                    <span></span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="submit-profile-div d-flex gap-3">
                                    <Button buttonText={"Cancel"} buttonType="secondary"/>
                                    <Button buttonText={"Save"} buttonType="primary"/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>
            <div className="profile-details-container mt-5">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.loginDetails")}</h2>
                    <p onClick={()=>{setLoginEdit(!loginEdit)}}><img src={EditIcon} alt="edit-icon"/>{t("accountSettingPage.userDetailsTab.edit")}</p>
                </div>
                {!loginEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="details mb-4">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.userName")}</p>
                                    <p className="value">Benjamin Doe</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.emailId")}</p>
                                    <p className="value">benjamin.doe@gmail.com</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="details">
                                    <p className="label">{t("accountSettingPage.userDetailsTab.password")}</p>
                                    <p className="value">
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {loginEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="details mb-4">
                                    <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={inputChange} inputValue={firstName}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Email'} inputType={'text'} inputLabel={'Email ID'} inputChange={inputChange} inputValue={firstName}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'New Password'} inputType={'password'} inputLabel={'New Password'} inputChange={inputChange} inputValue={newPassword}/>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details">
                                    <InputBox placeholder={'Confirm Password'} inputType={'password'} inputLabel={'Confirm Password'} inputChange={inputChange} inputValue={confirmPassword} errorMsg={isPasswordNotSame ? "New password and Confirm Password are different" : ""}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="5">
                            {newPassword !== '' && <div className="resetPassword-container">
                                <div className="password-match-container mb-4">
                                    <div className="passowrd-container d-flex justify-content-between">
                                        <div className="password-status">
                                            <span className="strength">{t("loginPage.resetPassword.strength")}</span>
                                            {passwordStrength === "weak" && <span className="tooweak">{t("loginPage.resetPassword.tooWeak")}</span>}
                                            {passwordStrength === "medium" && <span className="medium">{t("loginPage.resetPassword.medium")}</span>}
                                            {passwordStrength === "strong" && <span className="very-strong">{t("loginPage.resetPassword.veryStrong")}</span>}
                                        </div>
                                        <div className="password-strength-bar d-flex">
                                            <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'weak' ? 'weak' : passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'medium' ? 'medium' : passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                            <span className={`password-strength ${passwordStrength === 'strong' ? 'strong' : ''}`}></span>
                                        </div>                                        
                                    </div>
                                    <div className="password-strength-list">
                                        <ul type="none">
                                            <li className={`${totalChracterPassed ? 'active' : ''}`}><img src={totalChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.chracters")}</span></li>
                                            <li className={`${upperCasePassed ? 'active' : '' }`}><img src={upperCasePassed ?  tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.upperCase")}</span></li>
                                            <li className={`${lowerCasePassed ? 'active': ''}`}><img src={lowerCasePassed ? tickCircleGreen : tickCircle } alt="tick-circle" /><span>{t("loginPage.resetPassword.lowerCase")}</span></li>
                                            <li className={`${specialChracterPassed ? 'active' : ''}`}><img src={specialChracterPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.specialChracter")}</span></li>
                                            <li className={`${numberPassed ? 'active' : ''}`}><img src={numberPassed ? tickCircleGreen : tickCircle} alt="tick-circle" /><span>{t("loginPage.resetPassword.Numerical")}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="submit-profile-div d-flex gap-3">
                                    <Button buttonText={"Cancel"} buttonType="secondary"/>
                                    <Button buttonText={"Save"} buttonType="primary" buttonClicked={saveLoginDetils}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </div>
            <div className="profile-details-container mt-5">
                <div className="d-flex align-items-center justify-content-between profile-details">
                    <h2>{t("accountSettingPage.userDetailsTab.otherSettings")}</h2>
                    <p onClick={()=>{setIsOtherSettingEdit(!isOtherSettingEdit)}}><img src={EditIcon} alt="edit-icon"/>{t("accountSettingPage.userDetailsTab.edit")}</p>
                </div>
                {!isOtherSettingEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.apikeyGenerate")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <p className="value">*******_ ******_******</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.twoFactorAuthentication")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="switch-outer position-relative">
                                    <span></span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                {isOtherSettingEdit && <div className="profile-container p-3 rounded mt-3 pb-4">
                    <div className="details-section mt-3">
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.apikeyGenerate")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="details d-flex align-items-center gap-4 fs-5">
                                    <p className="value">*******_ ******_****** </p>
                                    <img src={CopyIcon} alt="copy-icon" className="api-key"/>  
                                    <img src={RefreshIcon} alt="refresh-icon" className="api-key"/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <div className="api-key-generator mb-4">
                                    <p>{t("accountSettingPage.userDetailsTab.twoFactorAuthentication")}</p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className={`switch-outer position-relative edit-mode ${is2FaActive ? "active" : ""}`} 
                                    onClick={()=>{activate2Fa()}}>
                                    <span></span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="submit-profile-div d-flex gap-3">
                                    <Button buttonText={"Cancel"} buttonType="secondary"/>
                                    <Button buttonText={"Save"} buttonType="primary"/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>}
                <Setup2FaModal show={modalShow} onHide={() => setModalShow(false)} />
                <UpdatePasswordModal show={showUpdatePasswordModal} onHide={() => setShowUpdatePasswordModal(false)} />
            </div>
        </>
    )
}

export default UserDetailsTab