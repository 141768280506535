
const initialState = {
    organizationList: null,
    usersList: null,
    addOrganizationResponse: null,
    organizationDetail: null,
    domainList: null,
    updateOrganizationResponse: null,
    deleteOrganizationResponse: null,
    addDomainResponse: null,
    assignUnAssignResponse: null
}

export default function organizationReducer(state=initialState, action) {
    switch(action.type) {
        case 'ORGANIZATION_LIST_SUCCESS' :
            return {
                ...state,
                organizationList: action.payload
            }
        case 'USERS_LIST_SUCCESS' :
            return {
                ...state,
                usersList: action.payload
            }
        case 'ADD_ORGANIZATION_SUCCESS' :
            return {
                ...state,
                addOrganizationResponse: action.payload
            }
        case 'ORGANIZATION_DETAILS_SUCCESS' :
            return {
                ...state,
                organizationDetail: action.payload
            }
        case 'DOMAIN_LIST_SUCCESS' : 
            return {
                ...state,
                domainList: action.payload
            }
        case 'RESET_ORGANIZATION_DETAILS' :
            return {
                ...state,
                organizationDetail: null
            }
        case 'UPDATE_ORGANIZATION_SUCCESS' :
            return {
                ...state,
                updateOrganizationResponse: action.payload
            }
        case 'RESET_UPDATE_ORGANIZATION_DETAIL' :
            return {
                ...state,
                updateOrganizationResponse: null
            }
        case 'DELETE_ORGANIZATION_SUCCESS' :
            return {
                ...state,
                deleteOrganizationResponse: action.payload
            }
        case 'RESET_DELETE_ORGANIZATION_RESPONSE':
            return {
                ...state,
                deleteOrganizationResponse: null
            }
        case 'RESET_ORGANIZATION_LIST' : 
            return {
                ...state,
                organizationList: null
            }
        case 'ADD_DOMAIN_SUCCESS':
            return {
                ...state,
                addDomainResponse: action.payload
            }
        case 'RESET_ADD_DOMAIN_RESPONSE': 
            return {
                ...state,
                addDomainResponse: null
            }
        case 'ASSIGN_UNASSIGN_SUCCESS' :
            return {
                ...state,
                assignUnAssignResponse: action.payload
            }
        case 'RESET_DOMAIN_LIST' :
            return {
                ...state,
                domainList: null
            }
        case 'RESET_USERS_LIST':
            return {
                ...state,
                usersList: null
            }
        case 'RESET_ASSIGN_UNASSIGN_RESPONSE': 
            return {
                ...state,
                assignUnAssignResponse: null
            } 
        default:
            return state;
    }
}