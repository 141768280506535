import { React } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";


import dummyProfileImage from "../../../../assets/images/side-nav/dummy-profile.png";


import ProfileAvatar from "../../../../components/ui/ProfileAvatar";


const UsersTileView = () => {

    const { t } = useTranslation();


    return (
        <>
            <Row>
                <Col xl="6" lg="12" md="12">
                    <div className="organization-container mt-4 px-4 py-3 rounded">
                        <div className="organization-profile">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="organization-details">
                                    <ProfileAvatar type="profile" secondarText={"Administrator | July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </div>
                                <div className="organization-actions">
                                    <button className="view-profile-btn">{t("users.viewBtn")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="user-scan-details d-flex gap-3 mt-4 justify-content-between">
                            <div className="total-scan p-3">
                                <p className="label">{t("users.totalScan")}</p>
                                <p className="value">64</p>
                            </div>
                            <div className="total-auto-scan p-3">
                                <p className="label">{t("users.totalAutoScan")}</p>
                                <p className="value">32</p>
                            </div>
                            <div className="domain-with-scan p-3">
                                <p className="label">{t("users.domainWithAccess")}</p>
                                <p className="value">20</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl="6" lg="12" md="12">
                    <div className="organization-container mt-4 px-4 py-3 rounded">
                        <div className="organization-profile">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="organization-details">
                                    <ProfileAvatar type="profile" secondarText={"Administrator | July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </div>
                                <div className="organization-actions">
                                    <button className="view-profile-btn">{t("users.viewBtn")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="user-scan-details d-flex gap-3 mt-4 justify-content-between">
                            <div className="total-scan p-3">
                                <p className="label">{t("users.totalScan")}</p>
                                <p className="value">64</p>
                            </div>
                            <div className="total-auto-scan p-3">
                                <p className="label">{t("users.totalAutoScan")}</p>
                                <p className="value">32</p>
                            </div>
                            <div className="domain-with-scan p-3">
                                <p className="label">{t("users.domainWithAccess")}</p>
                                <p className="value">20</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default UsersTileView;