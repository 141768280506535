import { React } from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import "./index.scss";

import Button from "../../../ui/Button";

const DeleteDomainModal = (props) => {
    const { t } = useTranslation();
    const { domains } = props; // Destructure the domains prop

    return (
        <Modal
            {...props}
            size=""
            centered
            className="delete-organization-modal"
        >
            <Modal.Body>
                <div className="delete-organization-container">
                <h4>{t("domainDetailsPage.deleteDomain.heading")}</h4>
                    <div className="organization-related-details p-4 m-2">
                        <p>
                            {domains.length > 0 
                                ? `Are you sure you want to delete ${domains.length} ${domains.length === 1 ? 'domain' : 'domains'}?`
                                : t("scanQueuePage.deleteDomain.subText")
                            }
                        </p>
                    </div>
                    <div className="delete-organization-btn d-flex gap-3 m-2">
                        <Button buttonText="Cancel" buttonType={"secondary"} buttonClicked={props.onClose} />
                        <Button buttonText="Delete" buttonType={"primary"} buttonClicked={props.onClose} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteDomainModal;
