import axios from 'axios';

const axiosInstance = axios.create ({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    // timeout: 50000, // in  milliseconds
})

// request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // console.log('Request:', config); // Logs the request configuration
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        // console.log('Request Error:', error); // Logs any request errors
        return Promise.reject(error);
    }
);

// Response interceptor

axiosInstance.interceptors.response.use(
    (response) => {
        // console.log('Response:', response); // Logs the response data
        return response;
    },
    (error) => {
        // console.log('Response Error:', error); // Logs any response errors
        return Promise.reject(error);
    }
);


export async function apiGet (url) {
    try {
        const response = await axiosInstance.get(url);
        // console.log('Response Data:', response.data);
        return response;
    } catch (error) {
        if (error.response) {
            console.error("Server responded with:", error.response.data); // Check for detailed error info
            // console.error("Status code:", error.response.status);
        } else {
            console.error("Error:", error.message);
        }
    }
}

export async function apiPost(url, data, isFormData = false) {
    try {
        const headers = isFormData
            ? { 'Content-Type': 'application/x-www-form-urlencoded' } // Custom header for FormData
            : {}; // For JSON or other formats, no additional headers required

        const response = await axiosInstance.post(url, data, { headers });
        // console.log('Response Data:', response.data);
        return response
    } catch (error) {
        if (error.response) {
            console.error("Server responded with:", error.response.data);
            // console.error("Status code:", error.response.status);
            return error.response.data
        } else {
            console.error("Error:", error.message);
        }
    }
}