import { React, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import "./index.scss";

import InputBox from "../../components/ui/InputBox";
import CheckBox from "../../components/ui/CheckBox";
import RadioButton from '../../components/ui/RadioButton';
import DropDown from '../../components/ui/DropDown';
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import Button from '../../components/ui/Button';
import SideModal from '../../components/ui/SideModal';
import FileUpload from "../../components/ui/FileUpload";
import Pagination from '../../components/ui/Pagination';
import Spinner from "../../components/ui/spinner";


const ComponentsPage = () => {
    const [username, setUserName] = useState('')
    const [checkboxValue, setCheckboxValue] = useState([])
    const [showSidePanel, setShowSidePanel] = useState(false)
    const [showSidePanelTwo, setShowSidePanelTwo] = useState(false)
    const [files, setFiles] = useState([]);
    const [radioValue, setRadioValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1);

    const allToppings = [
        { name: "Golden Corn", checked: false },
        { name: "Paneer", checked: false },
        { name: "Tomato", checked: false },
        { name: "Mushroom", checked: false },
        { name: "Onion", checked: false },
        { name: "Black Olives", checked: false },
    ]

    const [options] = useState([
        {
          label: "Option 1",
          value: "opt1",
        },
        {
          label: "Option 2",
          value: "opt2",
        },
        {
          label: "Option 3",
          value: "opt3",
        },
    ])

    const inputChange = (val) => {
        setUserName(val)
    }

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setCheckboxValue([...checkboxValue, e.target.value])
        } else {
            let value = checkboxValue
            let idx = value.indexOf(e.target.value)
            if(idx >= 0) {
                value.splice(idx, 1);
            }
            setCheckboxValue(value)
        }
    }

    const setRadioChange = (value) => {
        setRadioValue(value)
    }
    
    const handleChangeSelect = (e) => {
        console.log(e)
    }

    const buttonClicked = () => {
        alert('clicked')
    }

    const showSideModal= () => {
        setShowSidePanel(true)
    }

    const hideSidePanel = () => {
        setShowSidePanel(false)
    }

    const showSideModalTwo = () => {
        setShowSidePanelTwo(true)
    }

    const hideSidePanelTwo = () => {
        setShowSidePanelTwo(false)
    }

    useEffect(() => {
        console.log('files',files)
    },[checkboxValue, files]);

    return (
        <Container fluid>
            <Row>
                <Col md="12" className='my-5'>
                    <h2>Input box</h2>
                        <InputBox placeholder={'Username'} inputType={'text'} inputLabel={'Username'} inputChange={inputChange} inputValue={username}/>
                    <h2>DropDown - single select</h2>
                        <DropDown
                            options={options}
                            placeHolder='Please select...'
                            onChange={(e) => handleChangeSelect(e)}
                            isSearchable = {false}
                            isMulti = {false}
                        />
                    <h2 className="my-5">DropDown - multi select</h2>
                        <DropDown
                            options={options}
                            placeHolder='Please select...'
                            onChange={(e) => handleChangeSelect(e)}
                            isSearchable = {false}
                            isMulti
                        />
                    <h2 className="my-5">DropDown - with search</h2>
                        <DropDown
                            options={options}
                            placeHolder='Please select...'
                            onChange={(e) => handleChangeSelect(e)}
                            isSearchable
                            isMulti = {false}
                        />
                    <h2>Single checkbox</h2>
                        <CheckBox inputLabel={'Checkbox'} index={0} checkBoxId={'sampleChebox'} handleCheckboxChange={setCheckboxvalue} checkBoxValue={'panner'}/>
                    <h2>Multiple checkbox</h2>
                    {allToppings.map((data, index)=>{
                        return (
                            <CheckBox inputLabel={data.name} key={index} checkBoxId={data.name+index} handleCheckboxChange={setCheckboxvalue} checkBoxValue={data.name}/>
                        )
                    })}
                    <h2>disabled checkbox</h2>
                        <CheckBox inputLabel={'disabled'} index={0} checkBoxId={'disabled'} handleCheckboxChange={setCheckboxvalue} checkBoxValue={'panner'} disable={true}/>
                    <h2>Radio Button</h2>
                        <RadioButton radioId={'test'} radioLabel={'Option 1'} radioValue={'option1'} onChange={setRadioChange} isChecked={radioValue === 'option1'}/>
                        <RadioButton radioId={'test1'} radioLabel={'Option 2'} radioValue={'option2'} onChange={setRadioChange} isChecked={radioValue === 'option2'}/>
                    <h2>disabled RadioButton</h2>
                        <RadioButton radioId={'test2'} radioLabel={'disabled'} radioValue={'option3'} onChange={setRadioChange} disabled={true} />
                    <h2>Profile Avatar</h2>
                        <ProfileAvatar type="profile" firstName={"Profile"} lastName={"Avatar"} imageUrl={"https://yt3.googleusercontent.com/MqRmk0z-iBYMq1bn721QxOvPOdPzoSTbRw3IWx-pOmfabD7bfnoE5JubcirQoMlUNLV3VItgTPw=s176-c-k-c0x00ffffff-no-rj"} />
                        <ProfileAvatar type="profile" firstName={"Profile"} lastName={"Avatar"} tags={true} tagContent={'Active'} tagColor={'green'} />
                        <ProfileAvatar type="domain" name={"domain dafault Avatar"} />
                    <h2>Primary button</h2>
                        <Button buttonText={"Primary button"} buttonType="primary" buttonClicked={buttonClicked}/>
                    <h2>Secondary button</h2>
                        <Button buttonText={"Secondary button"} buttonType="secondary" buttonClicked={buttonClicked}/>
                    <h2>link button</h2>
                        <Button buttonText={"link button"} buttonType="link" buttonClicked={buttonClicked}/>
                    <h2>Side modal</h2>
                        <Button buttonText={"Show Side Modal"} buttonType="link" buttonClicked={showSideModal}/>
                        <SideModal showSidePanel={showSidePanel} hideSidePanel={hideSidePanel}>
                            <ul className="list">
                                <li>Writing JavaScript</li>
                                <li className="list-item"> Running</li>
                                <li className="list-item"> Technical Writing</li>
                            </ul>
                        </SideModal>
                    <h2>Side modal-2</h2>
                        <Button buttonText={"Show Side Modal"} buttonType="link" buttonClicked={showSideModalTwo}/>
                        <SideModal showSidePanel={showSidePanelTwo} hideSidePanel={hideSidePanelTwo}>
                            <ul className="list">
                                <li>Writing JavaScript111111</li>
                                <li className="list-item"> Running</li>
                                <li className="list-item"> Technical Writing</li>
                            </ul>
                        </SideModal>
                    <h2>FileUpload</h2>
                    <div className='section'>
                        <FileUpload onFilesSelected={setFiles} />
                    </div>
                    <h2 className="my-4">Pagination</h2>
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={50}
                            pageSize={10}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    <h2>Spinner</h2>
                        <Spinner />
                </Col>
            </Row>
        </Container>
    )
}

export default ComponentsPage;