import { React, useEffect, useState, useRef, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import addIcon from "../../../../assets/images/common/add.svg";
import sendIcon from "../../../../assets/images/common/Send.svg";
import editIcon from "../../../../assets/images/common/edit-icon.svg";
import closeIcon from "../../../../assets/images/common/close-circle.svg";

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import Pagination from "../../../../components/ui/Pagination";
import CheckBox from "../../../../components/ui/CheckBox";
import Button from "../../../../components/ui/Button";
import AddDomain from "../AddDomain";
import ModifyDomain from "../ModifyDomain";
import SideModal from '../../../../components/ui/SideModal';
import Spinner from "../../../../components/ui/spinner";

import { useSelector, useDispatch } from 'react-redux';
import { getDomainList, resetAddDomainResponse, resetDomainList, resetAssignUnassignResponse } from '../../../../store/actions/organizationActions.js';
import { formatDate } from "../../../../utils/util.js";

const filterOption = [
    {
        label: "In Progress",
        value: "In Progress"
    },
    {
        label: "Completed",
        value: "Completed"
    },
    {
        label: "Pending",
        value: "Pending"
    }
]


const DomainTab = () => {

    const domainListResponse = useSelector((state) => state.organization?.domainList);
    const addDomainResponse = useSelector((state) => state.organization?.addDomainResponse);
    const assignUnAssignResponse = useSelector((state) => state.organization?.assignUnAssignResponse)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showAdddomainSidePanel, setShowAdddomainSidePanel] = useState(false);
    const [showModifySidePanel, setShowModifySidePanel] = useState(false);
    const [domainListState, setDomainListState] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(10);
    const [isLoading, setIsLoading] = useState(false)


    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const closeAddDomainSidePanel = () => {
        setShowAdddomainSidePanel(false)
    }

    const closeModifySidePanel = () => {
        setShowModifySidePanel(false)
    }

    const closeModifyModal = () => {
        closeModifySidePanel()
        dispatch(resetAssignUnassignResponse());
        if(assignUnAssignResponse?.detail?.status === "success") {
            dispatch(resetDomainList())
            fetchDomain()
        }
    }

    const getNameAndDate = (data) => {
       let date = formatDate(data.created_at)
       let role = data.created_by.role
       return `${role} | ${date}`
    }

    const setCurrentPageandRecordSize = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        if(pageSize !== currentPageSize) {
            setCurrentPageSize(pageSize);
            setCurrentPage(1);
        }
    }

    const searchDomain = () => {
        const url = `&page=${currentPage}&page_size=${currentPageSize}&query=${domainSearchValue}`;
        setIsLoading(true)
        dispatch(getDomainList(url))
    }

    const fetchDomain = useCallback(()=>{
        const url = `&page=${currentPage}&page_size=${currentPageSize}`;
        setIsLoading(true)
        dispatch(getDomainList(url));
    },[currentPage, currentPageSize, dispatch])


    const closeAddDomain = () => {
        closeAddDomainSidePanel()
        if(addDomainResponse != null) {
            if(addDomainResponse?.detail?.status === "success") {
                fetchDomain()
                dispatch(resetAddDomainResponse())
            }
        }
    }

    useEffect(()=>{
        fetchDomain()
    },[fetchDomain])

    useEffect(()=>{
        if(domainListResponse !== null){
            setDomainListState(domainListResponse)
            setIsLoading(false)
        }
    },[domainListResponse])

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])

    return (
        <>
            <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                <div className="search-container d-flex align-items-center gap-3">
                    <div className="position-relative">
                        <input className="search-input rounded" placeholder="Search Domains" value={domainSearchValue} onChange={(e)=>setDomainSearchValue(e.target.value)}/>
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                    </div>
                    <button className="search-btn" onClick={()=>{searchDomain()}}>{t("organizationDetailsPage.searchBtn")}</button>
                    <div className="position-relative">
                        <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                        {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                            <h6>{t("organizationDetailsPage.domainTable.filterBy")}</h6>
                            {filterOption.map((data,index)=>{
                                return (
                                    <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                        <p>{data.label}</p>
                                        <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                    </div>
                                )
                            })}
                            <div className="filter-apply-btn mt-3">
                                <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 ? true : false} buttonClicked={applyFilter}/>
                            </div>
                        </div>}
                    </div>
                    {!showFilter && <p className="selected-filters">
                        {
                            filterList.map((data,index)=>{
                                return (
                                    <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                )
                            })
                        }
                    </p>}
                </div>
                <div className="sort-edit-container">
                    <div className="container d-flex gap-3 align-items-center">
                        <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                            <select className="border-0">
                                <option value="latest score">Latest Score</option>
                                <option value="last scanned">Last scanned</option>
                                <option value="created">Created</option>
                            </select>
                        </p>
                        <button onClick={()=>{setShowModifySidePanel(true)}}>
                            <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.modifyBtn")}
                        </button>
                        <button onClick={()=>{setShowAdddomainSidePanel(true)}}>
                            <img src={addIcon} alt="delete" className="d-inline-block mx-1" />
                            {t("organizationDetailsPage.domainBtn")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="domain-table-container">

                { isLoading ?
                    <div className="d-flex align-items-center justify-content-center domain-loader" style={{marginTop:'10%'}}>
                        <Spinner />
                    </div>
                    :
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>{t("organizationDetailsPage.domainTable.domainDetails")}</th>
                                    <th>{t("organizationDetailsPage.domainTable.createdDetails")}</th>
                                    <th>{t("organizationDetailsPage.domainTable.scanProgress")}</th>
                                    <th>{t("organizationDetailsPage.domainTable.score")}</th>
                                    <th>{t("organizationDetailsPage.domainTable.lastScan")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    domainListState?.results.length > 0 &&
                                    domainListState?.results.map((data,index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <ProfileAvatar type="domain" name={data?.domain} imageUrl={data.logo} />
                                                </td>
                                                <td>
                                                    <ProfileAvatar pageType={"domainTable"} type="profile" firstName={data?.created_by?.name} lastName={""} imageUrl={data?.created_by?.avatar} domainDetail={getNameAndDate(data)}/>
                                                </td>
                                                <td>
                                                    {data.scan_status === "completed" && <div className="scan-progress">
                                                        <div className="completed"> 
                                                            <div> completed</div> 
                                                        </div>
                                                    </div>}
                                                    {data.scan_status === "new" && <div className="scan-progress">
                                                        <div className="new"> 
                                                            <div>New</div> 
                                                        </div>
                                                    </div>}
                                                    {data.scan_status === "queued" && <div className="scan-progress queued">
                                                        <h4>Queued</h4>
                                                        <div className="progress-container"> 
                                                            <div style={{width:'0%'}}></div> 
                                                        </div>
                                                    </div>}
                                                    {data.scan_status === "inprogress" && <div className="scan-progress inprogress">
                                                        <h4>{data.scan_percentage}%</h4>
                                                        <div className="progress-container"> 
                                                            <div style={{width:'60%'}}></div> 
                                                        </div>
                                                    </div>}
                                                </td>
                                                <td>
                                                    <p className="score">{data?.stats.score?.latest_score?.overall_score?.obtained_score}</p>
                                                </td>
                                                <td>
                                                    <p>July 21, 2024 5:48</p>
                                                </td>
                                                <td>
                                                    <p className="view-scan">{t("organizationDetailsPage.domainTable.viewScan")}<img src={sendIcon} alt="view scan" /></p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                }
                {
                    !isLoading && domainListState?.results.length === 0 && 
                    <div className="d-flex align-items-center justify-content-center">
                        <h2>No Data found</h2>
                    </div>
                }
                {domainListResponse !== null && !isLoading && domainListResponse.meta.total_results !== 0 && <Pagination
                     className="pagination-bar"
                     currentPage={currentPage}
                     pageSize={currentPageSize}
                     totalCount={domainListResponse.meta.total_results}
                     onPageChange={setCurrentPageandRecordSize}
                />}
            </div>
            <SideModal showSidePanel={showAdddomainSidePanel} hideSidePanel={closeAddDomainSidePanel}>
                <AddDomain closeAddDomain={closeAddDomain}/>
            </SideModal>
            <SideModal showSidePanel={showModifySidePanel} hideSidePanel={closeModifySidePanel}>
                <ModifyDomain selectedTab={"domain"} clsoeModifyModal={closeModifyModal}/>
            </SideModal>
        </>
    )
}

export default DomainTab;