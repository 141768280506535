import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";

export function loginUser (data) {
  return async (dispatch) => {
      try {
          const response = await apiPost(apiEndpoints.common.login(), data, true);
          if(response?.data?.access_token) {
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: response.data,
            });
          } else {
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: response.detail,
            });
          }
      } catch (error) {
            console.log(error)
      }
  };
}

export function forgetPassword (data) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.common.forgotPassword(), data);
            dispatch({
                type: 'FORGET_PASSWORD_SUCCESS',
                payload: response.data,
            });
        } catch (error){
            console.log(error)
        }
    }
}

export function reset2FaAction (data) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.common.reset2Fa(), data);
            if(response?.detail?.status === "success") {
                dispatch({
                    type: 'RESET_2FA_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'RESET_2FA_SUCCESS',
                    payload: response.data,
                });
            }
            
        } catch (error){
            console.log(error)
        }
    }
}

export function resetPassword (data) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.common.resetPassword(), data);
            if(response?.data?.detail?.status === "success"){
                dispatch({
                    type: 'RESET_PASSWORD_SUCCESS',
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: 'RESET_PASSWORD_SUCCESS',
                    payload: response.detail,
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function verify2FaAccount (data) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.common.VerifyAccount(), data);
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: "VERIFY_ACCOUNT_SUCCESS",
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'VERIFY_ACCOUNT_SUCCESS',
                    payload: response.detail,
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
}


export function reset2faConfirmation(data) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.common.reset2FaConfirmation(), data);
            if(response?.detail?.status === "failed") {
                dispatch({
                    type: 'RESET_2FA_CONFIRMATION_SUCCESS',
                    payload: response.detail,
                });
            } else {
                dispatch({
                    type: 'RESET_2FA_CONFIRMATION_SUCCESS',
                    payload: response.data,
                });
            }
        } catch (error){
            console.log(error)
        }
    }
}

export function resetForgetPasswordResponse () {
    return (dispatch) => {
        dispatch({
            type: 'RESET_FORGET_PASSWORD_RESPONSE',
            payload: null,
        });
    }
}

export function resetReset2FaResponse () {
    return (dispatch) => {
        dispatch({
            type: 'RESET_RESET2FA_RESPONSE',
        });
    }
}

export function getLoggedInUserDetails(data) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.common.loggedInUserDetails()); 
            dispatch({
                type: 'LOGGED_IN_USER_DETAILS_SUCCESS',
                payload: response.data,
            });
            return response.data
        } catch (error){
            console.log(error)
        }
    }
}