import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import CallIcon from "../../assets/images/common/call.svg";
import GlobalIcon from "../../assets/images/common/global.svg";
import LogOutIcon from "../../assets/images/common/account-logout.svg";



import SideNav from "../../components/PostLogin/SideNav";
import UserDetailsTab from "../../components/PostLogin/AccountSettings/UserDetailsTab";
import UserSessionTab from "../../components/PostLogin/AccountSettings/UserSessionTab";


const AccountSettings = () => {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('userDetails');

    return (
        <div className="organization-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left">
                        <h1>{t("accountSettingPage.heading")}</h1>
                    </div>
                </div>
                <div className="page-main-section settings-main-page p-4">
                    <div className="organization-main-container py-3 mb-4">
                        <div className="orgs-tab-container d-flex rounded">
                            <p className={`rounded text-center ${activeTab === "userDetails" ? 'active' : ""}`} onClick={()=>{setActiveTab("userDetails")}}>{t("accountSettingPage.userDetails")}</p>
                            <p className={`rounded text-center ${activeTab === "activeSession" ? 'active' : ""}`} onClick={()=>{setActiveTab("activeSession")}}>{t("accountSettingPage.userSession")}</p>
                        </div>
                    </div>
                    {activeTab === "userDetails" && <UserDetailsTab />}
                    {activeTab === "activeSession" && <UserSessionTab />}
                    <div className="settings-footer-container mt-4 d-flex align-items-center justify-content-between">
                        <div className="about-us-container d-flex gap-5">
                            <div className="contact d-flex">
                                <p className="d-flex align-items-center gap-2"> <img src={CallIcon} alt="call"/> {t("accountSettingPage.contactUs")}</p>
                            </div>
                            <div className="contact">
                                <p className="d-flex align-items-center gap-2"><img src={GlobalIcon} alt="about cybermindr"/> {t("accountSettingPage.aboutCybermindr")}</p>
                            </div>
                        </div>
                        <div className="log-out-container">
                            <button><img src={LogOutIcon} alt="logout"/>{t("accountSettingPage.logout")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings