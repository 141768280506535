
// to get logged in user id 
export function getloggedInUserId() {
    let loggedInUserId = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserId.user_id
}

// to get logged in user role
export function getloggedInUserRole() {
    let loggedInUserRole = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserRole.role
}

// to get logged in user type
export function getloggedInUserType() {
    let loggedInUserType = JSON.parse(localStorage.getItem('loggedInUserDetails') || '{}');
    return loggedInUserType.user_type
}


export function formatNumber(num) {

    if (num === undefined || num === null) {
        return "0"; // Default to "0" or another placeholder if the value is undefined or null
    }

    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // 1M+
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + "K"; // 1K+
    } else {
        return num.toString(); // Less than 1K
    }
}


export function formatDate(dateString) {
    const date = new Date(dateString)

    // format date
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    });

    // Format the time part (e.g., "5:48")
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
    });

    return `${formattedDate} ${formattedTime}`;
}

