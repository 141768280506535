import React from 'react';
import { useTranslation } from "react-i18next";

import './index.scss';

const ProfileAvatar = ({imageUrl, type, firstName, lastName, name, tags, tagContent, tagColor, pageType, secondarText, domainDetail= ""}) => {

    const { t } = useTranslation();

    const isImageUrlNotAavilable = !imageUrl && type ==='domain' && name;

    return (
        <div className='profile-avatar-container'>
            <div className="image-container">
                {imageUrl && (
                    <img className="avatarImage" alt="avatar" src={imageUrl} />
                )}
                {!imageUrl && type==="profile" && lastName === "" && (
                    <div className="avatartext">{firstName?.substring(0, 2)}</div>
                )}
                {!imageUrl && type==="profile" && lastName !== "" && (
                    <div className="avatartext">{firstName?.substring(0, 1)}{lastName.substring(0, 1)}</div>
                )}
                {isImageUrlNotAavilable && type==="domain" && (
                    <div className="avatartext">{name?.substring(0, 2)}</div>
                )}
            </div>
            <div>
                {type==="profile" && pageType === "organizationDetails" && <p className="organization-details-createdby">{t("organizationDetailsPage.createdBy")}</p>}
                {type==="profile" && <p className="avatar-primary--text">{firstName} {lastName} 
                    {tags && <span className={`tag-content ${tagColor==='green' ? 'green' : ''}`}>
                        <span>{tagContent}</span>
                    </span>}
                </p>}
                {type==="domain" && <p className="avatar-primary--text">{name}</p>}
                {(type==="profile" && secondarText) && <p className="avatar-secondary--text">{secondarText}</p>}
                {pageType === "organizationHomePage" && 
                    <p className="domain-list-section d-flex">
                        <span className="domain-list">google.com</span>
                        <span className="domain-border"></span>
                        <span className="domain-list">abc.xyz</span>
                        <span className="more-domains">+3</span>
                    </p>
                }
                {pageType === "domainTable" && <p className="domain-table">{domainDetail}</p>}
                {pageType === "domainHomePage" &&
                    <p className="domain-list-section d-flex">
                        <span className="domain-list">Created On: </span>
                        <span className="domain-list">July 21, 2024 | 05:48</span>
                    </p>
                }
            </div>
        </div>
    )
}

export default ProfileAvatar;