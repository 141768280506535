import { React } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.scss";


import criticalIcon from "../../../../assets/images/organization/critical.svg";
import highIcon from "../../../../assets/images/organization/high.svg";
import mediumIcon from "../../../../assets/images/organization/medium.svg";
import LowIcon from "../../../../assets/images/organization/low.svg";
import portfolioIcon from "../../../../assets/images/organization/portfolio.svg";

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";

import { formatNumber } from "../../../../utils/util";


const OrganizationTileView = ({organizationList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const totalNewFindings = (data) => {
        let {asset_discovery, external_detected_vulnerabilities, darkweb, concerning_exposures } = data
        return formatNumber(asset_discovery + external_detected_vulnerabilities + darkweb + concerning_exposures)
    }

    const totalDomainRisk = (data) => {
        let {critical_risk, high_risk, medium_risk, low_risk } = data
        return formatNumber(critical_risk + high_risk + medium_risk + low_risk)
    }

    return (
        <>
            <Row>            
            {
                organizationList?.results.length > 0 ? 
                    organizationList?.results.map((data, index)=>{
                        return (
                            <Col xl="6" lg="12" md="12" key={index}>
                                <div className="organization-container mt-4 px-4 py-3 rounded">
                                    <div className="organization-profile">
                                        <div className="d-flex justify-content-between">
                                            <div className="organization-details">
                                                <ProfileAvatar pageType={"organizationHomePage"} type="domain" name={data.name} imageUrl={data.logo} />
                                            </div>
                                            <div className="organization-actions">
                                                <button className="view-details-btn" onClick={()=>{navigate(`/organization/details/${data.organization_id}`)}}>{t("organizationPage.viewDetailsBtn")}</button>
                                                <button className="view-scan-btn">{t("organizationPage.viewScanBtn")}</button>
                                            </div>
                                        </div>
                                        <div className="organization-status">
                                            {data.business_impact === "critical" && <p className="crictical">{t("organizationPage.BusinessImpact")} <span><img src={criticalIcon} alt="critical" />{data.business_impact}</span></p>}
                                            {data.business_impact === "high" && <p className="high">{t("organizationPage.BusinessImpact")} <span><img src={highIcon} alt="high" />{data.business_impact}</span></p>}
                                            {data.business_impact === "medium" && <p className="medium">{t("organizationPage.BusinessImpact")} <span><img src={mediumIcon} alt="medium" />{data.business_impact}</span></p>}
                                            {data.business_impact === "low" && <p className="low">{t("organizationPage.BusinessImpact")} <span><img src={LowIcon} alt="low" />{data.business_impact}</span></p>}
                                            <p></p>
                                            <p className="porfolio">{t("organizationPage.BusinessType")} <span><img src={portfolioIcon} alt="critical" />{data.type}</span></p>
                                        </div>
                                    </div>
                                    <div className="organization-findings mt-4">
                                        <Row>
                                            <Col md="6">
                                                <div className="new-findings-container">
                                                    <h2>{t("organizationPage.newFindings")}</h2>
                                                    <div className="new-findings-list rounded p-3">
                                                        <h4 className="m-0">{totalNewFindings(data.stats.new_findings)}</h4>
                                                        <p>{t("organizationPage.newFindings")}</p>
                                                        <div className="finding-list">
                                                            <ul type="none" className="m-0 p-0">
                                                                <li className="d-flex justify-content-between">
                                                                    <span>{t("organizationPage.assetDiscovery")}</span>
                                                                    <span>{formatNumber(data.stats.new_findings.asset_discovery)}</span>
                                                                </li>
                                                                <li className="d-flex justify-content-between">
                                                                    <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                                    <span>{formatNumber(data.stats.new_findings.external_detected_vulnerabilities)}</span>
                                                                </li>
                                                                <li className="d-flex justify-content-between">
                                                                    <span>{t("organizationPage.DarkWeb")}</span>
                                                                    <span>{formatNumber(data.stats.new_findings.darkweb)}</span>
                                                                </li>
                                                                <li className="d-flex justify-content-between">
                                                                    <span>{t("organizationPage.concerningExposures")}</span>
                                                                    <span>{formatNumber(data.stats.new_findings.concerning_exposures)}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="total-domains">
                                                    <h2>{t("organizationPage.domains")}</h2>
                                                    <div className="total-domains-container rounded p-3">
                                                        <h4 className="m-0 mt-4">{totalDomainRisk(data.stats.domain_portfolio_risks)}</h4>
                                                        <p>{t("organizationPage.totalRisk")}</p>
                                                        <div className="risk-count">
                                                            <Row>
                                                                <Col md={6}><p className="critical-risk-count mb-4"><span>{formatNumber(data.stats.domain_portfolio_risks.critical_risk)}</span>{t("organizationPage.critical")}</p></Col>
                                                                <Col md={6}><p className="high-risk-count mb-4"><span>{formatNumber(data.stats.domain_portfolio_risks.high_risk)}</span>{t("organizationPage.high")}</p></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}><p className="medium-risk-count mb-2"><span>{formatNumber(data.stats.domain_portfolio_risks.low_risk)}</span>{t("organizationPage.medium")}</p></Col>
                                                                <Col md={6}><p className="low-risk-count mb-2"><span>{formatNumber(data.stats.domain_portfolio_risks.medium_risk)}</span>{t("organizationPage.low")}</p></Col>
                                                            </Row>
                                                        </div>                                              
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                : "No result found"
            }
            </Row>
        </>
    )
}

export default OrganizationTileView;