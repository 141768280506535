import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import CheckBox from "../../../../../components/ui/CheckBox";

const IpAddress = ({ onCheckboxChange }) => {
    const { t } = useTranslation();
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxToggle = (id) => {
        const updatedCheckboxes = selectedCheckboxes.includes(id)
            ? selectedCheckboxes.filter((item) => item !== id)
            : [...selectedCheckboxes, id];

        setSelectedCheckboxes(updatedCheckboxes);

        // Notify parent component of the number of selected checkboxes
        onCheckboxChange(updatedCheckboxes.length);
    };

    return (
        <>
            <Table responsive="lg">
                <thead>
                    <tr>
                        <th className="select-all-checkbox">
                            <CheckBox checkBoxId={0} checkBoxValue={"select all"} handleCheckboxChange={() => handleCheckboxToggle('all')} />
                        </th>
                        <th>{t("domainDetailsPage.subDomainTable.ipAddress")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.clientProvided")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.firstSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.lastSeen")}</th>
                        <th>{t("domainDetailsPage.subDomainTable.status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Row 1 */}
                    <tr>
                        <td>
                            <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={() => handleCheckboxToggle(1)} />
                        </td>
                        <td>
                            <p>1.1.1.1</p>
                        </td>
                        <td>
                            <p>No</p>
                        </td>
                        <td>
                            <p>April 21, 2024 05:48</p>
                        </td>
                        <td>
                            <p>July 21, 2024 5:48</p>
                        </td>
                        <td>
                            <label className="asset-status active">Include</label>
                        </td>

                    </tr>
                    {/* Row 2 */}
                    <tr>
                        <td>
                            <CheckBox checkBoxId={2} checkBoxValue={"2"} handleCheckboxChange={() => handleCheckboxToggle(2)} />
                        </td>
                        <td>
                            <p>1.1.1.1</p>
                        </td>
                        <td>
                            <p>Yes</p>
                        </td>
                        <td>
                            <p>April 21, 2024 05:48</p>
                        </td>
                        <td>
                            <p>August 12, 2024 9:15</p>
                        </td>
                        <td>
                            <label className="asset-status disabled">Exclude</label>
                        </td>

                    </tr>
                    {/* Row 3 */}
                    <tr>
                        <td>
                            <CheckBox checkBoxId={3} checkBoxValue={"3"} handleCheckboxChange={() => handleCheckboxToggle(3)} />
                        </td>
                        <td>
                            <p>1.1.1.1</p>
                        </td>
                        <td>
                            <p>Yes</p>
                        </td>
                        <td>
                            <p>April 21, 2024 05:48</p>
                        </td>
                        <td>
                            <p>September 3, 2024 11:20</p>
                        </td>
                        <td>
                            <label className="asset-status active">Include</label>
                        </td>

                    </tr>
                    {/* Row 4 */}
                    <tr>
                        <td>
                            <CheckBox checkBoxId={4} checkBoxValue={"4"} handleCheckboxChange={() => handleCheckboxToggle(4)} />
                        </td>
                        <td>
                            <p>1.1.1.1</p>
                        </td>
                        <td>
                            <p>No</p>
                        </td>
                        <td>
                            <p>April 21, 2024 05:48</p>
                        </td>
                        <td>
                            <p>October 5, 2024 2:30</p>
                        </td>
                        <td>
                            <label className="asset-status disabled">Exclude</label>
                        </td>

                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default IpAddress;
