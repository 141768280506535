import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import login from "../../../../assets/images/common/login.svg";
import logout from "../../../../assets/images/common/logout.svg";

import Pagination from '../../../../components/ui/Pagination';
import AddDomain from "../../../../components/PostLogin/OrganizationDetails/AddDomain";
import SideModal from '../../../../components/ui/SideModal';
import SubDomains from '../../../../components/PostLogin/DomainDetails/AssetsTab/SubDomains';
import IpAddress from '../../../../components/PostLogin/DomainDetails/AssetsTab/IpAddress';
import Emails from '../../../../components/PostLogin/DomainDetails/AssetsTab/Emails';

// const filterCheckboxOption = [
//     { label: "Administrator", value: "Administrator" },
//     { label: "User", value: "User" },
//     { label: "Read Only", value: "Read Only" }
// ];

// const filterRadioOption = [
//     { label: "Both", value: "Both" },
//     { label: "Active", value: "Active" },
//     { label: "Inactive", value: "Inactive" }
// ];

const AssetsTab = () => {
    const { t } = useTranslation();
    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState("subdomains");

    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showAdddomainSidePanel, setShowAdddomainSidePanel] = useState(false);

    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedIpAddressesCount, setSelectedIpAddressesCount] = useState(0);
    const [selectedEmailsCount, setSelectedEmailsCount] = useState(0);

    const handleCheckboxChange = (count) => {
        if (activeTab === "subdomains") {
            setSelectedCount(count);
        } else if (activeTab === "ipAddress") {
            setSelectedIpAddressesCount(count);
        } else if (activeTab === "mails") {
            setSelectedEmailsCount(count);
        }
    };

    const getSelectedLabel = () => {
        if (activeTab === "subdomains" && selectedCount > 0) {
            return `${selectedCount} Sub-Domains selected`;
        }
        if (activeTab === "ipAddress" && selectedIpAddressesCount > 0) {
            return `${selectedIpAddressesCount} IP Addresses selected`;
        }
        if (activeTab === "mails" && selectedEmailsCount > 0) {
            return `${selectedEmailsCount} Emails selected`;
        }
        return null;
    };

    const [latestScoreFilter, setLatestScoreFilter] = useState("latest score");

    const filterClicked = () => {
        setShowFilter(true);
    };

    const closeAddDomainSidePanel = () => {
        setShowAdddomainSidePanel(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if (showFilter) {
                    setShowFilter(false);
                    setFilterList([]);
                }
            }
        }
    }, [showFilter, filterList]);

    return (
        <>
            <div className="assets-container d-flex rounded mb-3">
                <p className={`rounded text-center pointer ${activeTab === "subdomains" ? "active" : ""}`} onClick={() => setActiveTab("subdomains")}>
                    Subdomains {activeTab === "subdomains" && <div className="active-line"></div>}
                </p>
                <p className={`rounded text-center pointer ${activeTab === "ipAddress" ? "active" : ""}`} onClick={() => setActiveTab("ipAddress")}>
                    IP Address {activeTab === "ipAddress" && <div className="active-line"></div>}
                </p>
                <p className={`rounded text-center pointer ${activeTab === "mails" ? "active" : ""}`} onClick={() => setActiveTab("mails")}>
                    Emails  {activeTab === "mails" && <div className="active-line"></div>}
                </p>
            </div>

            <p className='border-bottom'></p>

            <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                <div className="search-container d-flex align-items-center gap-3">
                    <div className="position-relative">
                        <input className="search-input rounded" placeholder="Search Sub-Domain" value={domainSearchValue} onChange={(e) => setDomainSearchValue(e.target.value)} />
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                    </div>
                    <button className="search-btn">{t("organizationDetailsPage.searchBtn")}</button>
                    <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked} />
                </div>
                <div className="sort-edit-container">
                    <div className="container d-flex gap-3 align-items-center">
                        <p className="sort-by d-flex gap-1">
                            {t("organizationDetailsPage.usersTable.sortBy")}
                            <select className="border-0" value={latestScoreFilter} onChange={(e) => setLatestScoreFilter(e.target.value)}>
                                <option value="latest score">Latest Score</option>
                                <option value="last scanned">Last Scanned</option>
                                <option value="created">Created</option>
                            </select>
                        </p>
                        {getSelectedLabel() && (
                            <button className="borderless-btn">
                                {getSelectedLabel()}
                            </button>
                        )}

                        <button className='bg-white' disabled={selectedCount === 0}>
                            <img src={login} alt="include" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.includeBtn")}

                        </button>
                        <button className='bg-white' disabled={selectedCount === 0} onClick={() => { setShowAdddomainSidePanel(true) }}>
                            <img src={logout} alt="exclude" className="d-inline-block mx-1" />
                            {t("domainDetailsPage.excludeBtn")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="domain-table-container">
                {activeTab === "subdomains" && <SubDomains onCheckboxChange={(count) => handleCheckboxChange(count)} />}
                {activeTab === "ipAddress" && <IpAddress onCheckboxChange={(count) => handleCheckboxChange(count)} />}
                {activeTab === "mails" && <Emails onCheckboxChange={(count) => handleCheckboxChange(count)} />}
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={100}
                    pageSize={10}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
            <SideModal showSidePanel={showAdddomainSidePanel} hideSidePanel={closeAddDomainSidePanel}>
                <AddDomain />
            </SideModal>
        </>
    )
}

export default AssetsTab;
