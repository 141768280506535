import React, { useEffect, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import dummyProfile from "../../../../assets/images/side-nav/dummy-profile.png";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import sendIcon from "../../../../assets/images/common/Send.svg";
import OrganizationDummyImage from "../../../../assets/images/common/organization-dummy-image1.png";
import downloadIcon from "../../../../assets/images/common/download.svg";

import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import Pagination from "../../../../components/ui/Pagination";
import CheckBox from "../../../../components/ui/CheckBox";
import Button from "../../../../components/ui/Button";
import DeleteDomainModal from '../../../../components/PostLogin/DomainDetails/DeleteDomainModal';

const filterOption = [
    {
        label: "In Progress",
        value: "In Progress"
    },
    {
        label: "Completed",
        value: "Completed"
    },
    {
        label: "Pending",
        value: "Pending"
    }
];

const DomainTab = () => {
    const { t } = useTranslation();
    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const setCheckboxvalue = (e) => {
        if (e.target.checked) {
            setFilterList([...filterList, e.target.value]);
        } else {
            removeFilter(e.target.value);
        }
    };

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    };

    const applyFilter = () => {
        setShowFilter(false);
    };

    const filterClicked = () => {
        setShowFilter(true);
    };

    const handleDeleteButtonClick = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if (showFilter) {
                    setShowFilter(false);
                    setFilterList([]);
                }
            }
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilter, filterList]);

    return (
        <>
            <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                <div className="search-container d-flex align-items-center gap-3">
                    <div className="position-relative">
                        <input
                            className="search-input rounded"
                            placeholder="Search Domains"
                            value={domainSearchValue}
                            onChange={(e) => setDomainSearchValue(e.target.value)}
                        />
                        <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                    </div>
                    <button className="search-btn">{t("organizationDetailsPage.searchBtn")}</button>
                    <div className="position-relative">
                        <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked} />
                        {showFilter && (
                            <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                <h6>{t("organizationDetailsPage.domainTable.filterBy")}</h6>
                                {filterOption.map((data, index) => {
                                    return (
                                        <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                            <p>{data.label}</p>
                                            <CheckBox
                                                checkBoxId={index}
                                                checkBoxValue={data.value}
                                                handleCheckboxChange={setCheckboxvalue}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="filter-apply-btn mt-3">
                                    <Button
                                        buttonText={"Apply"}
                                        buttonType={"primary"}
                                        disableButton={filterList.length === 0}
                                        buttonClicked={applyFilter}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="sort-edit-container">
                    <div className="container d-flex gap-3 align-items-center">
                        <p className="sort-by d-flex gap-1">
                            {t("organizationDetailsPage.domainTable.sortBy")}
                            <select className="border-0">
                                <option value="latest score">Latest Score</option>
                                <option value="last scanned">Last scanned</option>
                                <option value="created">Created</option>
                            </select>
                        </p>
                        {filterList.length > 0 && (
                            <p className="selected-domains-count">
                                {filterList.length} {filterList.length === 1 ? "domain" : "domains"} selected
                            </p>
                        )}
                        <button
                            onClick={handleDeleteButtonClick}
                            disabled={filterList.length === 0} // Disable if no checkboxes are selected
                        >
                            {t("domainDetailsPage.deleteBtn")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="domain-table-container">
                <Table responsive="lg">
                    <thead>
                        <tr>
                            <th className="select-all-checkbox">
                                <CheckBox checkBoxId={2} checkBoxValue={"select all"} handleCheckboxChange={setCheckboxvalue} />
                            </th>
                            <th>{t("domainDetailsPage.domainTable.domainDetails")}</th>
                            <th>{t("domainDetailsPage.domainTable.scannedDetails")}</th>
                            <th>{t("domainDetailsPage.domainTable.scanType")}</th>
                            <th>{t("domainDetailsPage.domainTable.score")}</th>
                            <th>{t("domainDetailsPage.domainTable.threats")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4].map(id => (
                            <tr key={id}>
                                <td>
                                    <CheckBox checkBoxId={id} checkBoxValue={id.toString()} handleCheckboxChange={setCheckboxvalue} />
                                </td>
                                <td>
                                    <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                </td>
                                <td>
                                    <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                </td>
                                <td>
                                    <label className={id % 2 === 0 ? 'scan-status active' : 'scan-status passive'}>
                                        {id % 2 === 0 ? 'Active' : 'Passive'}
                                    </label>
                                </td>
                                <td>
                                    <p className="score">{Math.floor(Math.random() * (900 - 300 + 1)) + 300}</p>
                                </td>
                                <td>
                                    <p>200 New Findings</p>
                                </td>
                                <td>
                                    <p className="view-scan">
                                        {t("organizationDetailsPage.domainTable.viewScan")}
                                        <img src={sendIcon} alt="view scan" /> <span className="border-divider"></span> <img src={downloadIcon} alt="download" />
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={100}
                    pageSize={10}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>

            {/* DeleteDomainModal */}
            {showDeleteModal && (
                <DeleteDomainModal
                    show={showDeleteModal}
                    onClose={closeDeleteModal}
                    domains={filterList}
                />
            )}
        </>
    );
};

export default DomainTab;
