import { React, useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import "./index.scss";

import searchIcon from "../../../../assets/images/common/search-icon.svg";
import dummyProfile from "../../../../assets/images/side-nav/dummy-profile.png";
import filterIcon from "../../../../assets/images/common/filter-icon.svg";
import editIcon from "../../../../assets/images/common/edit-icon.svg";
import OrganizationDummyImage  from "../../../../assets/images/common/organization-dummy-image1.png";
import closeIcon from "../../../../assets/images/common/close-circle.svg";
import sendIcon from "../../../../assets/images/common/Send.svg";
import DownloadIcon from "../../../../assets/images/common/download-icon.svg";

import ProfileAvatar from '../../../../components/ui/ProfileAvatar';

import CheckBox from "../../../../components/ui/CheckBox";
import Button from "../../../../components/ui/Button";
import Pagination from "../../../../components/ui/Pagination";


const filterOption = [
    {
        label: "Critical",
        value: "critical"
    },
    {
        label: "High",
        value: "high"
    },
    {
        label: "Medium",
        value: "medium"
    },
    {
        label: "Low",
        value: "low"
    }
]


const ScanHistoryTab = () => {

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filterList, setFilterList] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showAdddomainSidePanel, setShowAdddomainSidePanel] = useState(false);
    const [showModifySidePanel, setShowModifySidePanel] = useState(false)

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])


    return (
        <Row>
            <Col md={12}>
                <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                    <div className="search-container d-flex align-items-center gap-3">
                        <div className="position-relative">
                            <input className="search-input rounded" placeholder="Search organization" value={domainSearchValue} onChange={(e)=>setDomainSearchValue(e.target.value)}/>
                            <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                        </div>
                        <button className="search-btn">Search</button>
                        <div className="position-relative">
                            <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                            {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                <h6>Business Impact</h6>
                                {filterOption.map((data,index)=>{
                                    return (
                                        <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                            <p>{data.label}</p>
                                            <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                        </div>
                                    )
                                })}
                                <div className="filter-apply-btn mt-3">
                                    <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 ? true : false} buttonClicked={applyFilter}/>
                                </div>
                            </div>}
                        </div>
                        {!showFilter && <p className="selected-filters">
                            {
                                filterList.map((data,index)=>{
                                    return (
                                        <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                    )
                                })
                            }
                        </p>}
                    </div>
                    <div className="sort-edit-container">
                        <div className="container d-flex gap-3 align-items-center">
                            <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                                <select className="border-0">
                                    <option value="latest score">Latest Score</option>
                                    <option value="last scanned">Last scanned</option>
                                    <option value="created">Created</option>
                                </select>
                            </p>
                            <button onClick={()=>{setShowModifySidePanel(true)}}>
                                <img src={editIcon} alt="edit" className="d-inline-block mx-1" />
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
                <div className="domain-table-container user-page-scanHistory-table">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th className="org-tabel-select-all"><CheckBox checkBoxId={2} checkBoxValue={"select all"} handleCheckboxChange={setCheckboxvalue}/></th>
                                <th>Domain Details</th>
                                <th>Scanned Details</th>
                                <th>Scan Type</th>
                                <th>Score</th>
                                <th>Threads</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={setCheckboxvalue}/>
                                </td>
                                <td>
                                    <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                </td>
                                <td>
                                    <ProfileAvatar type="profile" firstName={"Benjamin"} lastName={"Franklin"} secondarText={"lollypop | July 21, 2024 5:48"} imageUrl={dummyProfile} />
                                </td>
                                <td>
                                    <p className="scan-type active">Active</p>
                                </td>
                                <td>
                                    821
                                </td>
                                <td>
                                    <p>200 New Findings</p>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2 scan-history-action">
                                        <p className="view-scan">View Scan<img src={sendIcon} alt="view scan" /></p>
                                        <p className="scan-border"></p>
                                        <img src={DownloadIcon} alt="download" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={setCheckboxvalue}/>
                                </td>
                                <td>
                                    <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                </td>
                                <td>
                                    <ProfileAvatar type="profile" firstName={"Benjamin"} lastName={"Franklin"} secondarText={"lollypop | July 21, 2024 5:48"} imageUrl={dummyProfile} />
                                </td>
                                <td>
                                    <p className="scan-type active">Active</p>
                                </td>
                                <td>
                                    821
                                </td>
                                <td>
                                    <p>200 New Findings</p>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2 scan-history-action">
                                        <p className="view-scan">View Scan<img src={sendIcon} alt="view scan" /></p>
                                        <p className="scan-border"></p>
                                        <img src={DownloadIcon} alt="download" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={setCheckboxvalue}/>
                                </td>
                                <td>
                                    <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                </td>
                                <td>
                                    <ProfileAvatar type="profile" firstName={"Benjamin"} lastName={"Franklin"} secondarText={"lollypop | July 21, 2024 5:48"} imageUrl={dummyProfile} />
                                </td>
                                <td>
                                    <p className="scan-type passive">Passive</p>
                                </td>
                                <td>
                                    821
                                </td>
                                <td>
                                    <p>200 New Findings</p>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2 scan-history-action">
                                        <p className="view-scan">View Scan<img src={sendIcon} alt="view scan" /></p>
                                        <p className="scan-border"></p>
                                        <img src={DownloadIcon} alt="download"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={setCheckboxvalue}/>
                                </td>
                                <td>
                                    <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                </td>
                                <td>
                                    <ProfileAvatar type="profile" firstName={"Benjamin"} lastName={"Franklin"} secondarText={"lollypop | July 21, 2024 5:48"} imageUrl={dummyProfile} />
                                </td>
                                <td>
                                    <p className="scan-type passive">Passive</p>
                                </td>
                                <td>
                                    821
                                </td>
                                <td>
                                    <p>200 New Findings</p>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2 scan-history-action">
                                        <p className="view-scan">View Scan<img src={sendIcon} alt="view scan" /></p>
                                        <p className="scan-border"></p>
                                        <img src={DownloadIcon} alt="download"/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={100}
                        pageSize={10}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default ScanHistoryTab;