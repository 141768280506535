import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";

import InputBox from "../../../ui/InputBox";
import Button from "../../../ui/Button";


const AddDomainModal = () => {

    const { t } = useTranslation();

    const [organizationName, setOrganizationName] = useState('');
    const [domainURL, setDomainURL] = useState('');
    const [assignedOrganization, setAssignedOrganization] = useState('');
    const [addOrgStep, setAddOrgStep] = useState(1);
    const [isFormValid, setIsFormValid] = useState(false);

    // Input change handlers
    const handleOrganizationNameChange = (val) => {
        setOrganizationName(val);
        validateForm(val, domainURL, assignedOrganization);
    }

    const handleDomainURLChange = (val) => {
        setDomainURL(val);
        validateForm(organizationName, val, assignedOrganization);
    }

    const handleAssignedOrganizationChange = (val) => {
        setAssignedOrganization(val);
        validateForm(organizationName, domainURL, val);
    }

    // Form validation function
    const validateForm = (orgName, domain, organization) => {
        if (orgName && domain && organization) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const nextStep = () => {
        if (isFormValid) {
            setAddOrgStep(4);  // Move to success step
        }
    }

    const goBack = () => {
        // Logic to go back to main page or reset steps can be added here
    }

    return (
        <div className="add-organization-container">
             {addOrgStep !== 4 && (
                <>
            <div className="modal-header mt-4">
                <h2>{t("domainPage.addDomainModal.addDomain")}</h2>
            </div>

            {/* Conditionally render the form only if the step is not 4 */}
           
                    <h4>{t("domainPage.addDomainModal.domainDetails")}</h4>
                    <div className="add-organization-step-one mt-3">

                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Name of the Domain'}
                                inputType={'text'}
                                inputLabel={'What is the Name of your Domain?'}
                                inputChange={handleOrganizationNameChange}
                                inputValue={organizationName}
                            />
                        </div>
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Domain URL'}
                                inputType={'text'}
                                inputLabel={'Domain URL'}
                                inputChange={handleDomainURLChange}
                                inputValue={domainURL}
                            />
                        </div>
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Select Organization'}
                                inputType={'text'}
                                inputLabel={'Which Organization to Assign?'}
                                inputChange={handleAssignedOrganizationChange}
                                inputValue={assignedOrganization}
                            />
                        </div>

                    </div>

                    <div className="form-submit-btn-group mt-4">
                        <button className="cancel-btn">{t("organizationPage.addOrganizationModal.cancelButton")}</button>
                        <button className="save-btn" onClick={nextStep} disabled={!isFormValid}>
                            {t("organizationPage.addOrganizationModal.saveButton")}
                        </button>
                    </div>
                </>
            )}

            {/* Display success message when addOrgStep is 4 */}
            {addOrgStep === 4 && (
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    <div className="mt-5">
                        <img src={successTick} alt="success" />
                    </div>
                    <h2 className="mb-4 mt-5">{t("domainPage.addDomainModal.domainCreated")}</h2>
                    <p>{t("domainPage.addDomainModal.successMessage")}</p>
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddDomainModal;
