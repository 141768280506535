import React, { useEffect, useState } from "react";
import "./index.scss";

import galleryIcon from "../../../assets/images/file-upload/gallery-add.svg";
import cancelIcon from "../../../assets/images/file-upload/file-cancel.svg";

const FileUpload = ({
    onFilesSelected,
    label,
    filePath = ""
  }) => {
    const [files, setFiles] = useState([]);
    const [fileSrc, setFilesSrc] = useState('');
  
    const handleFileChange = (event) => {
      const selectedFiles = event.target.files;
      if (selectedFiles && selectedFiles.length > 0 && files.length === 0) {
        const newFiles = Array.from(selectedFiles);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        const reader = new FileReader();
        reader.readAsDataURL(selectedFiles[0]);
        reader.onload = () => {
          setFilesSrc(reader.result); // Set base64 string as file source
          onFilesSelected(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error converting file to base64:", error);
        };
        // setFilesSrc(URL.createObjectURL(event.target.files[0]));
      }
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0 && files.length === 0) {
        const newFiles = Array.from(droppedFiles);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        const reader = new FileReader();
        reader.readAsDataURL(droppedFiles[0]);
        reader.onload = () => {
          setFilesSrc(reader.result); // Set base64 string as file source
          onFilesSelected(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error converting file to base64:", error);
        };
      }
    };
  
    const handleRemoveFile = (index) => {
      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setFilesSrc('');
    };
  
    useEffect(() => {
      onFilesSelected(fileSrc);
    }, [files, onFilesSelected, fileSrc]);

    useEffect(()=>{
      if(filePath !== ""){
        setFilesSrc(filePath);
        let arr = [{
          name: filePath
        }]
        setFiles(arr)
      }
    },[filePath])
  
    return (
      <section className="drag-drop">
        <p className="file-label">{label}</p>
        <div
          className={`document-uploader ${
            files.length > 0 ? "upload-box active" : "upload-box"
          }`}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          {fileSrc === "" && <>
            <div className="upload-info">
              <div className="mb-2">
                <img src={galleryIcon} alt="organization"/>
              </div>
              <div>
                <p>Upload Image</p>
              </div>
            </div>
            <input
              type="file"
              hidden
              id="browse"
              onChange={handleFileChange}
              accept="image/png, image/gif, image/jpeg"
            />
            <label htmlFor="browse" className="browse-btn">
              Drag or click here to upload in PNG or JPG format
            </label>
          </>}
  
          {fileSrc !== "" && (
            <div className="file-list">
                {files.map((file, index) => (
                  <div className="file-item" key={index}>
                    <div className="file-info">
                      <div className="file-img">
                        <img className="w-100 h-100" src={fileSrc} alt="organization logo" />
                      </div>
                      <p>{file.name}</p>
                    </div>
                    <div className="file-actions">
                      <p onClick={() => handleRemoveFile(index)} ><img src={cancelIcon} alt="remove file"/></p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    );
  };
  
  export default FileUpload;