import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss"

import dummyProfile from "../../../assets/images/side-nav/dummy-profile.png";
import LogOutIcon from "../../../assets/images/common/account-logout-red.svg";
import accountSetting from "../../../assets/images/side-nav/account-setting.svg";


const SideNavProfile = () => {

    const { t } = useTranslation();

    const profileRef = useRef(null);

    const [showOption, setShowOption] = useState(false);

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setShowOption(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="profile-container position-relative" ref={profileRef}>
            <div className='profile-picture mt-4' onClick={()=>{setShowOption(!showOption)}}>
                <img className="w-100 h-100" src={dummyProfile} alt="profile"/>
            </div>
            {showOption && <div className="profile-container-option position-absolute">
                <p className="d-flex gap-3 align-items-center"><img src={accountSetting} alt="settings"/>{t("sideNav.userProfile.accountSetting")}</p>
                <p className="d-flex gap-3 align-items-center logout"><img src={LogOutIcon} alt="logout" />{t("sideNav.userProfile.logOut")}</p>
            </div>}
        </div>
    )
}

export default SideNavProfile;