import { React, useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";
import failed from "../../../../assets/images/common/failed.gif";

import FileUpload from "../../../ui/FileUpload";
import InputBox from "../../../ui/InputBox";
import RadioButton from '../../../ui/RadioButton';
import Button from "../../../ui/Button";
import DropDown from '../../../../components/ui/DropDown';

import { useSelector, useDispatch } from 'react-redux';
import { getUsersList, addOrganization, getDomainList, updateOrganizationDetail, resetUpdateOrganizationDetail } from '../../../../store/actions/organizationActions';

const AddOrganizationModal = ({backToMainPage, type="", organizationDetail=null, showAddOrgSidePanel}) => {

    const { t } = useTranslation();

    const { organizationId } = useParams();

    const usersListResponse = useSelector((state) => state.organization?.usersList);
    const domainListResponse = useSelector((state) => state.organization?.domainList);
    const addOrganizationResponse = useSelector((state) => state.organization?.addOrganizationResponse);
    const updateOrganizationResponse = useSelector((state)=> state.organization?.updateOrganizationResponse)

    const dispatch = useDispatch();

    const [organizationName,setOrganizationName] = useState("");
    const [organizationType, setOrganizationType] = useState('');
    const [businessImpact, setBusinessImpact] = useState('');
    const [selectedDomain, setSelectedDomain] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [addOrgStep,setAddOrgStep] = useState(1);
    const [files, setFiles] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [addOrganizationState,setAddOrganizationState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [domainList, setDomainList] = useState([]);
    const [userSkipped, setUserSkipped] = useState(false);
    const [updateOrganizationState, setUpdateOrganizationState] = useState(null)

    const inputChange = (val) => {
        setOrganizationName(val);
    }

    const organizationTypeChange = (value) => {
        setOrganizationType(value)
    }

    const businessImpactChange = (value) => {
        setBusinessImpact(value)
    }

    const nextStep = () => {
        addOrgStep === 1 ? setAddOrgStep(2) : addOrgStep === 2 ? setAddOrgStep(3) : addOrgStep === 3 ? setAddOrgStep(4) : setAddOrgStep(1)
    }

    const disableStepOne = () => {
        return organizationName === "" || organizationType === "" || businessImpact === "";
    };

    const handleSelectDomain = (value) => {
        setSelectedDomain(value)
    }

    const handleSelectUser = (value) => {
        setSelectedUser(value)
    }

    const getUploadfile = (file) => {
        setFiles(file)
    }

    const addOrgaization = () => {
        let obj = {
           logo: files,
           name: organizationName,
           organization_type: organizationType,
           business_impact: businessImpact
        }
        setIsLoading(true)
        dispatch(addOrganization(obj))
    }

    const backToPage = () => {
        setAddOrgStep(1);
        setOrganizationName("");
        setOrganizationType("");
        setBusinessImpact("");
        setFiles("");
        if(type === "edit") {
            backToMainPage(updateOrganizationState)
        } else {
            backToMainPage(addOrganizationState)
        }
        dispatch(resetUpdateOrganizationDetail())
    }

    const updateOrganization = () => {
        let obj = {
            logo: files,
            name: organizationName,
            organization_type: organizationType,
            business_impact: businessImpact,
            organization_id: organizationId
        }
        setIsLoading(true)
        dispatch(updateOrganizationDetail(obj))
    }

    const updatevalues = useCallback(()=>{
        if(organizationDetail) {
            setOrganizationName(organizationDetail.name || "");
            setOrganizationType(organizationDetail.type || "");
            setBusinessImpact(organizationDetail.business_impact || "");
            setFiles(organizationDetail.logo || "");
        }
    },[organizationDetail])

    useEffect(()=>{
        
        if(usersListResponse?.results){
            let list = usersListResponse?.results.map((data)=>{
                return {
                    id: data.user_id,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    value: data.user_id,
                    email: data.email,
                    logo: data.avatar,
                    label: data.firstname + " " + data.lastname
                }
            })
            setUsersList(list)
        }
    },[usersListResponse])

    useEffect(()=>{
        if(domainListResponse != null) {
            let list = domainListResponse?.results.map((data)=>{
                return {
                    id: data.domain_id,
                    name: data.domain,
                    value: data.domain_id,
                    logo: data.logo,
                    label: data.domain
                }
            })
            setDomainList(list)
        }
    },[domainListResponse])

    useEffect(()=>{
        dispatch(getUsersList(""))
        dispatch(getDomainList(""))
    },[dispatch])

    useEffect(()=>{
        setAddOrganizationState(addOrganizationResponse)
        if(addOrganizationResponse !== null){
            setIsLoading(false)
            setAddOrgStep(4)
        }
    },[addOrganizationResponse])

    useEffect(()=>{
        if(type==="edit" && updateOrganizationResponse !== null) {
            setIsLoading(false)
            setUpdateOrganizationState(updateOrganizationResponse)
        }
    },[updateOrganizationResponse, type])

    useEffect(()=>{
        if(type==="edit"){
            updatevalues();
        }
    },[type, organizationDetail, showAddOrgSidePanel, updatevalues])

    return (
        <>
            {type !== "edit" && <div className="add-organization-container">
                {addOrgStep <= 3 && <>
                    <div className="modal-header mt-4">
                        <h2>{t("organizationPage.addOrganizationModal.addOrganization")}</h2>
                    </div>
                    <div className="add-organization-step my-3">
                        <div className={`d-flex align-items-center justify-content-center step-indicator ${addOrgStep === 1 ? 'inprogress' : addOrgStep > 1 ? 'completed' : ''}`}></div>
                        <div className="step-border"></div>
                        <div className={`d-flex align-items-center justify-content-center step-indicator ${addOrgStep === 2 ? 'inprogress' : addOrgStep > 2 ? 'completed' : ''}`}>{addOrgStep < 2 ? '2' : ''}</div>
                        <div className="step-border"></div>
                        <div className={`d-flex align-items-center justify-content-center step-indicator ${addOrgStep === 3 ? 'inprogress' : ''}`}>{addOrgStep < 3 ? '3' : ''}</div>
                    </div>
                </>
                }
                {addOrgStep === 1 && <>
                    <h4>{t("organizationPage.addOrganizationModal.organizationDetails")}</h4>
                    <div className="add-organization-step-one mt-3">
                        <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} />
                        <div className="input-container mt-4">
                            <InputBox placeholder={'Enter Name of the Organization'} inputType={'text'} inputLabel={'What is the Name of your Organization'} inputChange={inputChange} inputValue={organizationName} />
                        </div>
                        <div className="organization-type">
                            <p>{t("organizationPage.addOrganizationModal.typeOfTheOrganization")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'portfolio'} radioLabel={'Portfolio'} radioValue={'portfolio'} onChange={organizationTypeChange}  isChecked={organizationType === 'portfolio'}/>
                                <RadioButton radioId={'internal'} radioLabel={'Internal'} radioValue={'internal'} onChange={organizationTypeChange} isChecked={organizationType === 'internal'}/>
                            </div>
                        </div>
                        <div className="organization-type mt-3">
                            <p>{t("organizationPage.addOrganizationModal.whatIsBusinessImpact")}</p>
                            <div className="radio-btn-group d-flex mt-3">
                                <RadioButton radioId={'crictical'} radioLabel={'Critical'} radioValue={'critical'} onChange={businessImpactChange} isChecked={businessImpact === 'critical'}/>
                                <RadioButton radioId={'high'} radioLabel={'High'} radioValue={'high'} onChange={businessImpactChange} isChecked={businessImpact === 'high'}/>
                                <RadioButton radioId={'Medium'} radioLabel={'Medium'} radioValue={'medium'} onChange={businessImpactChange} isChecked={businessImpact === 'medium'}/>
                                <RadioButton radioId={'Low'} radioLabel={'Low'} radioValue={'low'} onChange={businessImpactChange} isChecked={businessImpact === 'low'}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-submit-btn-group mt-4">
                        <Button buttonText={"Cancel"} buttonType="secondary" />
                        <Button buttonText={"Save"} buttonType="primary" buttonClicked={nextStep} disableButton={disableStepOne()} />
                    </div>
                </>}
                {addOrgStep === 2 && <>
                    <h4>{t("organizationPage.addOrganizationModal.domainDetails")}</h4>
                    <div className="add-organization-step-one mt-3 mb-5">
                        <DropDown
                            options={domainList}
                            placeHolder='Please select Domain'
                            dropDownType={"modifyDomain"}
                            onChange={(e) => handleSelectDomain(e)}
                            isSearchable = {true}
                            isMulti
                        />
                    </div>
                    <div className="form-submit-btn-group mt-4">
                        <Button buttonText={"Cancel"} buttonType="secondary" />
                        <Button buttonText={"Skip"} buttonType={"secondary"} buttonClicked={nextStep}/>
                        <Button buttonText={"Save"} buttonType="primary" buttonClicked={nextStep} disableButton={selectedDomain.length === 0}/>
                    </div>
                    </>
                }
                {addOrgStep === 3 && <>
                    <h4>{t("organizationPage.addOrganizationModal.inviteUsers")}</h4>
                    <div className="add-organization-step-one mt-3">
                        <DropDown
                            options={usersList}
                            placeHolder='Please select User'
                            dropDownType={"modifyUser"}
                            onChange={(e) => handleSelectUser(e)}
                            isSearchable = {true}
                            isMulti
                        />
                    </div>
                    <div className="form-submit-btn-group mt-4">
                        <Button buttonText={"Cancel"} buttonType="secondary" />
                        <Button buttonText={"Skip"} buttonType={"secondary"} buttonClicked={()=>{
                            setUserSkipped(true)
                        }}/>
                        {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={addOrgaization} disableButton={selectedUser.length === 0 && userSkipped === false}/>}
                        {isLoading && <Button buttonText={"Loading..."} buttonType="primary" buttonClicked={addOrgaization} disableButton={true}/>}
                    </div>
                    </>
                }
                {addOrgStep === 4 && <>
                    {addOrganizationResponse?.detail?.status === "success" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                            <img src={successTick} alt="success" />
                        </div>
                        <h2 className="mb-4 mt-5">{t("organizationPage.addOrganizationModal.organizationCreated")}</h2>
                        <p>{t("organizationPage.addOrganizationModal.successMessage")}</p>
                    </div>}
                    {addOrganizationResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                        <div className="mt-5">
                        <img src={failed} alt="failed" />
                        </div>
                        <h2 className="mb-4 mt-5">{addOrganizationResponse?.description}</h2>
                    </div>}
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={backToPage} />
                    </div>
                </>}
            </div>}
            {
                type === "edit" && 
                <>
                    {updateOrganizationResponse === null && <div className="add-organization-container">
                        <div className="modal-header mt-4">
                            <h2>Edit Organization</h2>
                        </div>
                        <div className="add-organization-step-one mt-3">
                            <FileUpload onFilesSelected={getUploadfile} label={'Upload an Avatar'} filePath={files}/>
                            <div className="input-container mt-4">
                                <InputBox placeholder={'Enter Name of the Organization'} inputType={'text'} inputLabel={'What is the Name of your Organization'} inputChange={inputChange} inputValue={organizationName} />
                            </div>
                            <div className="organization-type">
                                <p>{t("organizationPage.addOrganizationModal.typeOfTheOrganization")}</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'portfolio'} radioLabel={'Portfolio'} radioValue={'portfolio'} onChange={organizationTypeChange}  isChecked={organizationType === 'portfolio'}/>
                                    <RadioButton radioId={'internal'} radioLabel={'Internal'} radioValue={'internal'} onChange={organizationTypeChange} isChecked={organizationType === 'internal'}/>
                                </div>
                            </div>
                            <div className="organization-type mt-3">
                                <p>{t("organizationPage.addOrganizationModal.whatIsBusinessImpact")}</p>
                                <div className="radio-btn-group d-flex mt-3">
                                    <RadioButton radioId={'crictical'} radioLabel={'Critical'} radioValue={'critical'} onChange={businessImpactChange} isChecked={businessImpact === 'critical'}/>
                                    <RadioButton radioId={'high'} radioLabel={'High'} radioValue={'high'} onChange={businessImpactChange} isChecked={businessImpact === 'high'}/>
                                    <RadioButton radioId={'Medium'} radioLabel={'Medium'} radioValue={'medium'} onChange={businessImpactChange} isChecked={businessImpact === 'medium'}/>
                                    <RadioButton radioId={'Low'} radioLabel={'Low'} radioValue={'low'} onChange={businessImpactChange} isChecked={businessImpact === 'low'}/>
                                </div>
                            </div>
                        </div>
                        <div className="form-submit-btn-group mt-4">
                            <Button buttonText={"Cancel"} buttonType="secondary" />
                            {!isLoading && <Button buttonText={"Save"} buttonType="primary" buttonClicked={()=>{updateOrganization()}} disableButton={disableStepOne()} />}
                            {isLoading && <Button buttonText={"Loading..."} buttonType="primary" disableButton={true} />}
                        </div>
                    </div>}
                    {
                        updateOrganizationResponse !== null && <>
                            {updateOrganizationResponse?.detail?.status === "success" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                                <div className="mt-5">
                                    <img src={successTick} alt="success" />
                                </div>
                                <h2 className="mb-4 mt-5 fs-4">{updateOrganizationResponse?.detail?.description}</h2>
                            </div>}
                            {updateOrganizationResponse?.status === "failed" && <div className="addOrg-success-container d-flex align-items-center flex-column">
                                <div className="mt-5">
                                <img src={failed} alt="failed" />
                                </div>
                                <h2 className="mb-4 mt-5 fs-4">{updateOrganizationResponse?.description}</h2>
                            </div>}
                            <div className="btn-container mt-5">
                                <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={backToPage} />
                            </div>
                        </>
                        
                    }
                </>
            }
        </>
    )
}

export default AddOrganizationModal;