import React, { useState } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import "./index.scss";

import dummyProfileImage from "../../../../assets/images/common/dummy-logo.png";
import arrowRight from "../../../../assets/images/common/arrow-right-border.svg";
import trash from "../../../../assets/images/common/trash.svg";
import pause from "../../../../assets/images/domain/pause.svg";
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";
import Gauge from "./Gauge";
import GaugePro from "./GaugePro";
import ScanModal from "../../../../components/PostLogin/Domain/ScanModal";

const DomainTileView = () => {
    const { t } = useTranslation();
    const [scanningDomainId, setScanningDomainId] = useState(null);
    const [pausedDomainId, setPausedDomainId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal visibility state

    const handleStartScan = (id) => {
        setScanningDomainId(id);
        setPausedDomainId(null); // Reset paused state when starting a new scan
        setShowDeleteModal(true); // Open the modal
    };

    const handlePauseResume = (id) => {
        if (pausedDomainId === id) {
            setPausedDomainId(null); // Resume
        } else {
            setPausedDomainId(id); // Pause
        }
    };

    const handleCloseModal = () => setShowDeleteModal(false); // Close modal function

    return (
        <Row>
            {/* First Domain Section */}
            <Col xl="6" lg="12" md="12">
                <div className="domain-container mt-4 px-4 py-3 rounded">
                    <div className="organization-profile">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="organization-details">
                                <ProfileAvatar pageType={"domainHomePage"} type="domain" name={"alibaba.com"} imageUrl={dummyProfileImage} />
                            </div>
                            <div className="organization-actions">
                                <button
                                    className={`view-details-btn ${scanningDomainId === 1 ? "disabled-btn" : ""}`}
                                    onClick={() => handleStartScan(1)}
                                    disabled={scanningDomainId === 1}
                                >
                                    <img src={arrowRight} alt="arrowRight" />
                                    {t("domainPage.startScanBtn")}
                                </button>
                                <button className="view-scan-btn">{t("organizationPage.viewDetailsBtn")}</button>
                            </div>
                        </div>

                        <div className="mt-3">
                            <p>{t("domainPage.scanStatus")}: {scanningDomainId === 1 ? (pausedDomainId === 1 ? "Paused (24%)" : "24%") : "Yet To Start"}</p>
                            <div
                                className={`${scanningDomainId === 1 ? "scan-progress" : "mt-3"}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <ProgressBar
                                    now={scanningDomainId === 1 ? 25 : 0}
                                    style={{ height: '8px', width: '100%' }}
                                    className={`custom-progress-bar ${pausedDomainId === 1 ? 'paused-progress' : ''}`} // Add conditional class
                                />

                                {scanningDomainId === 1 && (
                                    <>
                                        <button className="view-details-btn-scan" onClick={() => handlePauseResume(1)}>
                                            <img src={pausedDomainId === 1 ? arrowRight : pause} alt={pausedDomainId === 1 ? "resume" : "pause"} />
                                        </button>
                                        <button
                                            className={`view-details-btn-trash ${pausedDomainId === 1 ? "" : "disabled-btn-grey"}`}
                                            disabled={pausedDomainId !== 1}
                                        >
                                            <img src={trash} alt="trash" />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Domain Findings and Ratings Section */}
                    <div className="organization-findings">
                        <Row>
                            <Col md="6">
                                <div className="total-domains">
                                    <div className="total-domains-container rounded p-4">
                                        <h2>{t("domainPage.riskRatings")}</h2>
                                        <Gauge />
                                        <div className="domain-align d-flex align-items-center justify-content-between mt-4 mb-3">
                                            <div className='domain-bottom'>
                                                <div>
                                                    <h2>A+</h2>
                                                    <p>Infrastructure</p>
                                                </div>
                                                <div className='domain-sec'>
                                                    <h2>B</h2>
                                                    <p>Exposure</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>A+</h2>
                                                    <p>Vulnerabilities</p>
                                                </div>
                                                <div className='domain-sec'>
                                                    <h2>A</h2>
                                                    <p>Dark Web</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="new-findings-container">
                                    <div className="new-findings-list rounded p-4">
                                        <h2>{t("domainPage.totalFindings")}</h2>
                                        <h4 className="m-0">00</h4>
                                        <p>{t("organizationPage.newFindings")}</p>
                                        <div className="finding-list">
                                            <ul className="m-0 p-0">
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.assetDiscovery")}</span>
                                                    <span>0</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                    <span>0</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.DarkWeb")}</span>
                                                    <span>0</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.concerningExposures")}</span>
                                                    <span>0</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>

            {/* Second Domain Section */}
            <Col xl="6" lg="12" md="12">
                <div className="domain-container mt-4 px-4 py-3 rounded">
                    <div className="organization-profile">
                        <div className="d-flex justify-content-between border-bottom">
                            <div className="organization-details">
                                <ProfileAvatar pageType={"domainHomePage"} type="domain" name={"alibaba.com"} imageUrl={dummyProfileImage} />
                            </div>
                            <div className="organization-actions">
                                <button
                                    className={`view-details-btn ${scanningDomainId === 2 ? "disabled-btn" : ""}`}
                                    onClick={() => handleStartScan(2)}
                                    disabled={scanningDomainId === 2}
                                >
                                    <img src={arrowRight} alt="arrowRight" />
                                    {t("domainPage.startScanBtn")}
                                </button>
                                <button className="view-scan-btn">{t("organizationPage.viewDetailsBtn")}</button>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{t("domainPage.scanStatus")}: {scanningDomainId === 2 ? (pausedDomainId === 2 ? "Paused (24%)" : "24%") : "Completed"}</p>
                                <p>{t("domainPage.lastScan")}: June 21, 2024 | 05:48</p>
                            </div>

                            <div
                                className={`${scanningDomainId === 2 ? "scan-progress" : "mt-3"}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <ProgressBar
                                    now={scanningDomainId === 2 ? 25 : 0}
                                    style={{ height: '8px', width: '100%', marginRight: '10px' }}
                                    className={`custom-progress-bar ${pausedDomainId === 2 ? 'paused-progress' : ''}`} // Add conditional class
                                />

                                {scanningDomainId === 2 && (
                                    <>
                                        <button className="view-details-btn-scan" onClick={() => handlePauseResume(2)}>
                                            <img src={pausedDomainId === 2 ? arrowRight : pause} alt={pausedDomainId === 2 ? "resume" : "pause"} />
                                        </button>
                                        <button
                                            className={`view-details-btn-trash ${pausedDomainId === 2 ? "" : "disabled-btn-grey"}`}
                                            disabled={pausedDomainId !== 2}
                                        >
                                            <img src={trash} alt="trash" />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Findings and Rating Section */}
                    <div className="organization-findings">
                        <Row>
                            <Col md="6">
                                <div className="total-domains">
                                    <div className="total-domains-container rounded p-4">
                                        <h2>{t("domainPage.riskRatings")}</h2>
                                        <GaugePro />
                                        <div className="domain-align d-flex align-items-center justify-content-between mt-4 mb-3">
                                            <div className='domain-bottom'>
                                                <div>
                                                    <h2>A+</h2>
                                                    <p>Infrastructure</p>
                                                </div>
                                                <div className='domain-sec'>
                                                    <h2>B</h2>
                                                    <p>Exposure</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <h2>A+</h2>
                                                    <p>Vulnerabilities</p>
                                                </div>
                                                <div className='domain-sec'>
                                                    <h2>A</h2>
                                                    <p>Dark Web</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="new-findings-container">
                                    <div className="new-findings-list rounded p-4">
                                        <h2>{t("domainPage.totalFindings")}</h2>
                                        <h4 className="m-0">10,000</h4>
                                        <p>{t("organizationPage.newFindings")}</p>
                                        <div className="finding-list">
                                            <ul className="m-0 p-0">
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.assetDiscovery")}</span>
                                                    <span>100</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.externalDetectedVulnerabilities")}</span>
                                                    <span>100,000</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.DarkWeb")}</span>
                                                    <span>100</span>
                                                </li>
                                                <li className="d-flex justify-content-between">
                                                    <span>{t("organizationPage.concerningExposures")}</span>
                                                    <span>1,000</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>

            {/* Delete Domain Modal */}
            <ScanModal show={showDeleteModal} onHide={handleCloseModal} />
        </Row>
    );
};

export default DomainTileView;
