import { apiGet, apiPost } from "../../utils/axios.js";
import apiEndpoints from "../../apiEndpoints.js";


export function getOrganizationList (url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.organizations.organizationList()+url);
            dispatch({
                type: 'ORGANIZATION_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    };
}

export function getUsersList (url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.users.usersList()+url);
            dispatch({
                type: 'USERS_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function addOrganization (obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.organizations.addOrganization(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'ADD_ORGANIZATION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ADD_ORGANIZATION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function getOrganizationDetails(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.organizations.organizationDetails(), obj)
            dispatch({
                type: 'ORGANIZATION_DETAILS_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getDomainList(url) {
    return async (dispatch) => {
        try {
            const response = await apiGet(apiEndpoints.domains.domainList()+url)
            dispatch({
                type: 'DOMAIN_LIST_SUCCESS',
                payload: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetOrganizationDetail() {
    return (dispatch)=>{
        dispatch({
            type: 'RESET_ORGANIZATION_DETAILS'
        })
    }
}

export function resetUpdateOrganizationDetail() {
    return (dispatch)=>{
        dispatch({
            type: 'RESET_UPDATE_ORGANIZATION_DETAIL'
        })
    }
}

export function updateOrganizationDetail(obj) {
    return async (dispatch)=>{
        try {
            const response = await apiPost(apiEndpoints.organizations.updateOrganizationDetail(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'UPDATE_ORGANIZATION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'UPDATE_ORGANIZATION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function deleteOrganization(obj) {
    return async (dispatch) => {
        try {
            const response = await apiPost(apiEndpoints.organizations.deleteOrganization(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'DELETE_ORGANIZATION_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'DELETE_ORGANIZATION_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function resetDeleteOrganizationResponse() {
    return (dispatch) =>{
        dispatch({
            type: 'RESET_DELETE_ORGANIZATION_RESPONSE'
        })
    }
}

export function resetOrganizationListResponse () {
    return (dispatch) => {
        dispatch ({
            type: 'RESET_ORGANIZATION_LIST'
        })
    }
}

export function addDomain(obj) {
    return async (dispatch)=>{
        try {
            const response = await apiPost(apiEndpoints.domains.addDoamin(), obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'ADD_DOMAIN_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ADD_DOMAIN_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error){
            console.log(error)
        }
    }
}

export function resetAddDomainResponse() {
    return (dispatch)=>{
        dispatch({
            type: 'RESET_ADD_DOMAIN_RESPONSE'
        })
    }
}

export function resetDomainList() {
    return (dispatch)=>{
        dispatch({
            type: 'RESET_DOMAIN_LIST'
        })
    }
}

export function resetUsersList() {
    return (dispatch)=>{
        dispatch({
            type: 'RESET_USERS_LIST'
        })
    }
}

export function resetAssignUnassignResponse() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_ASSIGN_UNASSIGN_RESPONSE'
        })
    }
}

export function assignAndUnAssign(url, obj) {
    return async (dispatch)=>{
        try {
            const response = await apiPost(url, obj)
            if(response?.data?.detail?.status === "success") {
                dispatch({
                    type: 'ASSIGN_UNASSIGN_SUCCESS',
                    payload: response.data
                })
            } else {
                dispatch({
                    type: 'ASSIGN_UNASSIGN_SUCCESS',
                    payload: response.detail
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
}