import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import "./index.scss";

import successTick from "../../../../assets/images/common/Success.gif";

import FileUpload from "../../../ui/FileUpload";
import InputBox from "../../../ui/InputBox";


const EditDomainModal = () => {

    const { t } = useTranslation();

    const [organizationName,setOrganizationName] = useState([]);
    const [files, setFiles] = useState([]);

    const inputChange = (val) => {
        setOrganizationName(val);
    }

    const goBack = () => {
    }

    return (
        <div className="add-organization-container">
           
         
           <div className="modal-header mt-4">
                    <h2>{t("domainPage.editDomainModal.editDomain")}</h2>
                </div>
                <div className="add-organization-step-one mt-3">
                    <FileUpload onFilesSelected={setFiles} label={'Upload an Avatar'}/>
                    <div className="input-container mt-4">
                        <InputBox placeholder={'Enter Name of the Organization'} inputType={'text'} inputLabel={'What is the Name of your Organization'} inputChange={inputChange} inputValue={organizationName}/>
                    </div>
                  
                    
                </div>
                <div className="form-submit-btn-group mt-4">
                <button className="cancel-btn">{t("organizationPage.addOrganizationModal.cancelButton")}</button>
                <button className="save-btn" >{t("organizationPage.addOrganizationModal.saveButton")}</button>
            </div>
        
           
            {/* {addOrgStep === 4 && <>
                <div className="addOrg-success-container d-flex align-items-center flex-column">
                    <div className="mt-5">
                        <img src={successTick} alt="success" />
                    </div>
                    <h2 className="mb-4 mt-5">{t("organizationPage.addOrganizationModal.organizationCreated")}</h2>
                    <p>{t("organizationPage.addOrganizationModal.successMessage")}</p>
                    <div className="btn-container mt-5">
                        <Button buttonText={"Back to Main Page"} buttonType="primary" buttonClicked={goBack} />
                    </div>
                </div>
            </>} */}
        </div>
    )
}

export default EditDomainModal;