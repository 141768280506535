import { React, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';

import "./index.scss";


import dummyProfileImage from "../../../../assets/images/side-nav/dummy-profile.png";


import Button from "../../../../components/ui/Button";
import Pagination from '../../../../components/ui/Pagination';
import ProfileAvatar from "../../../../components/ui/ProfileAvatar";


const UserListView = () => {

    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);


    return (
        <Row>
            <Col md="12">
                <div className="list-view-container users-list-view-table p-3">
                    <Table responsive="lg">
                        <thead>
                            <tr>
                                <th>{t("users.userListView.users")}</th>
                                <th>{t("users.userListView.role")}</th>
                                <th>{t("users.userListView.totalScan")}</th>
                                <th>{t("users.userListView.totalAutoScan")}</th>
                                <th>{t("users.userListView.domainWithAccess")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ProfileAvatar type="profile" secondarText={"July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </td>
                                <td>
                                    <p>Administrator</p>
                                </td>
                                <td>
                                    <p>64</p>
                                </td>
                                <td>
                                    <p>32</p>
                                </td>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <div className='organization-list-view d-flex'>
                                        <Button buttonText={"View"} buttonType="primary" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="profile" secondarText={"July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </td>
                                <td>
                                    <p>Administrator</p>
                                </td>
                                <td>
                                    <p>64</p>
                                </td>
                                <td>
                                    <p>32</p>
                                </td>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <div className='organization-list-view d-flex'>
                                        <Button buttonText={"View"} buttonType="primary" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="profile" secondarText={"July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </td>
                                <td>
                                    <p>Administrator</p>
                                </td>
                                <td>
                                    <p>64</p>
                                </td>
                                <td>
                                    <p>32</p>
                                </td>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <div className='organization-list-view d-flex'>
                                        <Button buttonText={"View"} buttonType="primary" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ProfileAvatar type="profile" secondarText={"July 21, 2024 | 5:48"} firstName={"John"} lastName={"Doe"} imageUrl={dummyProfileImage} />
                                </td>
                                <td>
                                    <p>Administrator</p>
                                </td>
                                <td>
                                    <p>64</p>
                                </td>
                                <td>
                                    <p>32</p>
                                </td>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <div className='organization-list-view d-flex'>
                                        <Button buttonText={"View"} buttonType="primary" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={100}
                        pageSize={10}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default UserListView;