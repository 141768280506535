import { React, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import "./index.scss";

import searchIcon from "../../assets/images/common/search-icon.svg";
import dummyProfile from "../../assets/images/side-nav/dummy-profile.png";
import filterIcon from "../../assets/images/common/filter-icon.svg";
import OrganizationDummyImage  from "../../assets/images/common/organization-dummy-image1.png";
import closeIcon from "../../assets/images/common/close-circle.svg";
import StartIcon from "../../assets/images/scan-queue/strat-icon.svg";
import StartDisabledIcon from "../../assets/images/scan-queue/start-disabled.svg";
import PauseIcon from "../../assets/images/scan-queue/pause-icon.svg";
import PauseDisabledIcon from "../../assets/images/scan-queue/pause-disabled.svg";
import DeleteIcon from "../../assets/images/scan-queue/delete-icon.svg";
import DeleteDisabledIcon from "../../assets/images/scan-queue/delete-disabled.svg";
import SelectAllPauseIcon from "../../assets/images/scan-queue/pause.svg";

import SideNav from "../../components/PostLogin/SideNav";
import ProfileAvatar from '../../components/ui/ProfileAvatar';
import Pagination from "../../components/ui/Pagination";
import CheckBox from "../../components/ui/CheckBox";
import RadioButton from "../../components/ui/RadioButton";
import Button from "../../components/ui/Button";

import DeleteDomainModal from "../../components/PostLogin/ScanQueue/DeleteDomainModal";


const filterOption = [
    {
        label: "In Progress",
        value: "In Progress"
    },
    {
        label: "Paused",
        value: "Paused"
    },
    {
        label: "Queued",
        value: "Queued"
    }
]

const filterRadioOption = [
    {
        label: "Both",
        value: "Both"
    },
    {
        label: "Active",
        value: "Active"
    },
    {
        label: "Passive",
        value: "Passive"
    }
]

const ScanQueue = () => {

    const { t } = useTranslation();

    const filterWrapperRef = useRef(null);

    const [domainSearchValue, setDomainSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filterList, setFilterList] = useState([]);
    const [filterStatusValue, setFilterStatusvalue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const setCheckboxvalue = (e) => {
        if(e.target.checked) {
            setFilterList([...filterList, e.target.value])
        } else {
            removeFilter(e.target.value)
        }
    }

    const removeFilter = (data) => {
        const updatedList = filterList.filter(item => item !== data);
        setFilterList(updatedList);
        setShowFilter(false);
    }

    const applyFilter = () => {
        setShowFilter(false)
    }

    const filterClicked = () => {
        setShowFilter(true)
    }

    const filterStatusChanges = (value) => {
        setFilterStatusvalue(value)
    }

    const removeStatus = () => {
        setFilterStatusvalue("")
    }

    const showModal = () => {
        setModalShow(true);
    };

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);

        function handleClickOutside(event) {
            if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target)) {
                if(showFilter){
                    setShowFilter(false)
                    setFilterList([])
                }
            }
        
        }
    },[showFilter,filterList])


    return (
        <div className="organization-details-container">
            <SideNav />
            <div className="organization-page-container">
                <div className="page-header-container px-4 py-3 d-flex align-items-center justify-content-between">
                    <div className="header-left d-flex align-items-center gap-3">
                        <h1>{t("scanQueuePage.heading")}</h1>
                    </div>
                </div>
                <div className="page-main-section px-4 py-3">
                    <div className="organization-header-container p-3 my-3">
                        <div className="d-flex gap-3 mt-3">
                            <div className="total-score-container rounded scan-queue-count">
                                <div className="users-totol-count d-flex">
                                    <p className="total-user-count">50</p>
                                    <div className="user-count mx-3 mt-2">
                                        <span className="active d-inline-block mx-1"></span>
                                        <span className="disbled d-inline-block"></span>
                                        <p>{t("scanQueuePage.scans")}</p>
                                    </div>
                                </div>
                                <div className="active-disabled-count d-flex gap-3">
                                    <p className="active-user"><span className="d-inline-block">40</span> {t("scanQueuePage.activeScans")}</p>
                                    <p className="disabled-user"><span className="d-inline-block">10</span> {t("scanQueuePage.passiveScans")}</p>
                                </div>
                            </div>
                            <div className="total-domains-container scan-queue-page rounded">
                                <p className="total-domain">32</p>
                                <p className="domain-text">{t("scanQueuePage.adhocScan")}</p>
                            </div>
                            <div className="total-domains-container scan-queue-page rounded">
                                <p className="total-domain">18</p>
                                <p className="domain-text">{t("scanQueuePage.autoScan")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="organization-main-container p-3">
                        <div className="search-filter-container my-3 d-flex justify-content-between align-items-center">
                            <div className="search-container d-flex align-items-center gap-3">
                                <div className="position-relative">
                                    <input className="search-input rounded" placeholder="Search Scan" value={domainSearchValue} onChange={(e)=>setDomainSearchValue(e.target.value)}/>
                                    <img src={searchIcon} alt="search" className="position-absolute search-icon" />
                                </div>
                                <button className="search-btn">{t("scanQueuePage.search")}</button>
                                <div className="position-relative">
                                    <img src={filterIcon} alt="filter" className="filter-icon" onClick={filterClicked}/>
                                    {showFilter && <div className="filters-container position-absolute p-3 rounded" ref={filterWrapperRef}>
                                        <h6>{t("organizationDetailsPage.domainTable.filterBy")}</h6>
                                        {filterOption.map((data,index)=>{
                                            return (
                                                <div className="filter-option d-flex justify-content-between rounded my-2" key={index}>
                                                    <p>{data.label}</p>
                                                    <CheckBox checkBoxId={index} checkBoxValue={data.value} handleCheckboxChange={setCheckboxvalue}/>
                                                </div>
                                            )
                                        })}
                                        <h6 className="mt-4">Status</h6>
                                        <div className="filter-user-status d-flex mb-4 gap-4">
                                            {
                                                filterRadioOption.map((data,index)=>{
                                                    return (
                                                        <RadioButton radioId={data.label} radioValue={data.value} radioLabel={data.label} handleRadioChange={filterStatusChanges} key={index}/>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="filter-apply-btn mt-3">
                                            <Button buttonText={"Apply"}  buttonType={"primary"} disableButton={filterList.length === 0 && filterStatusValue === '' ? true : false} buttonClicked={applyFilter}/>
                                        </div>
                                    </div>}
                                </div>
                                {!showFilter && <p className="selected-filters">
                                    {
                                        filterList.map((data,index)=>{
                                            return (
                                                <span className="d-inline-block mx-2" key={index}>{data} <img src={closeIcon} alt="close" onClick={() => removeFilter(data)}/></span>
                                            )
                                        })
                                    }
                                    {filterStatusValue !== '' && <span className="d-inline-block mx-2" >{filterStatusValue} <img src={closeIcon} alt="close" onClick={removeStatus}/></span>}
                                </p>}
                            </div>
                            <div className="sort-edit-container scan-queue-page">
                                <div className="container d-flex gap-3 align-items-center">
                                    <p className="sort-by d-flex gap-1">{t("organizationDetailsPage.domainTable.sortBy")} 
                                        <select className="border-0">
                                            <option value="latest score">Latest Score</option>
                                            <option value="last scanned">Last scanned</option>
                                            <option value="created">Created</option>
                                        </select>
                                    </p>
                                    <button className="pause-scan-btn">
                                        <img src={SelectAllPauseIcon} alt="pause" className="d-inline-block mx-1" />
                                        {t("scanQueuePage.pauseScan")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="domain-table-container scan-queue-table">
                            <Table responsive="lg">
                                <thead>
                                    <tr>
                                        <th className="select-all-checkbox"><CheckBox checkBoxId={2} checkBoxValue={"select all"} handleCheckboxChange={setCheckboxvalue}/></th>
                                        <th>{t("scanQueuePage.domainDetails")}</th>
                                        <th>{t("scanQueuePage.scanDetails")}</th>
                                        <th>{t("scanQueuePage.scanType")}</th>
                                        <th>{t("scanQueuePage.scanStatus")}</th>
                                        <th>{t("scanQueuePage.checkpoint")}</th>
                                        <th>{t("scanQueuePage.scanStoped")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <CheckBox checkBoxId={1} checkBoxValue={"1"} handleCheckboxChange={setCheckboxvalue}/>
                                        </td>
                                        <td>
                                            <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                        </td>
                                        <td>
                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                        </td>
                                        <td>
                                            <label className="scan-type active">Active</label></td>
                                        <td>
                                            <div className="scan-progress inprogress">
                                                <h4>In progress - 60%</h4>
                                                <div className="progress-container"> 
                                                    <div style={{width:'60%'}}></div> 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="score">Gitleaks Scan</p>
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 scan-action-btn">
                                                <img src={PauseIcon} alt="pause-disabled"/>
                                                <img src={DeleteDisabledIcon} alt="delete-disabled"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CheckBox checkBoxId={2} checkBoxValue={"2"} handleCheckboxChange={setCheckboxvalue}/>
                                        </td>
                                        <td>
                                            <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                        </td>
                                        <td>
                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                        </td>
                                        <td>
                                            <label className="scan-type active">Active</label>
                                        </td>
                                        <td>
                                            <div className="scan-progress paused">
                                                <h4>Paused - 90%</h4>
                                                <div className="progress-container"> 
                                                    <div style={{width:'90%'}}></div> 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="score">Gitleaks Scan</p>
                                        </td>
                                        <td>
                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 scan-action-btn">
                                                <img src={StartIcon} alt="pause-disabled"/>
                                                <img src={DeleteIcon} alt="delete-disabled" onClick={showModal}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CheckBox checkBoxId={3} checkBoxValue={"3"} handleCheckboxChange={setCheckboxvalue}/>
                                        </td>
                                        <td>
                                            <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                        </td>
                                        <td>
                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                        </td>
                                        <td>
                                            <label className="scan-type passive">Passive</label>
                                        </td>
                                        <td>
                                            <div className="scan-progress queued">
                                                <h4>Queued</h4>
                                                <div className="progress-container"> 
                                                    <div style={{width:'0%'}}></div> 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="score">Gitleaks Scan</p>
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 scan-action-btn">
                                                <img src={StartDisabledIcon} alt="pause-disabled"/>
                                                <img src={DeleteIcon} alt="delete-disabled"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CheckBox checkBoxId={4} checkBoxValue={"4"} handleCheckboxChange={setCheckboxvalue}/>
                                        </td>
                                        <td>
                                            <ProfileAvatar type="domain" name={"Lollypop"} imageUrl={OrganizationDummyImage} />
                                        </td>
                                        <td>
                                            <ProfileAvatar pageType={"domainTable"} type="profile" firstName={"Benjamin"} lastName={"Franklin"} imageUrl={dummyProfile} />
                                        </td>
                                        <td>
                                            <label className="scan-type passive">Passive</label>
                                        </td>
                                        <td>
                                            <div className="scan-progress inprogress">
                                                <h4>In progress - 60%</h4>
                                                <div className="progress-container"> 
                                                    <div style={{width:'60%'}}></div> 
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="score">Gitleaks Scan</p>
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 scan-action-btn">
                                                <img src={PauseDisabledIcon} alt="pause-disabled"/>
                                                <img src={DeleteDisabledIcon} alt="delete-disabled"/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={100}
                                pageSize={10}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                    <DeleteDomainModal show={modalShow} onHide={() => setModalShow(false)} />
                </div>
            </div>
        </div>
    )
}

export default ScanQueue;